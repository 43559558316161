<template>
  <background-transparent-view :closeModal="closeModal" :backgroundModal="backgroundModal"></background-transparent-view>
  <div v-if="isLoading" class="d-flex align-items-center" style="height: 100%">
    <div class="loading-spinner"></div>
  </div>
  <div v-else class="container-fluid">
    <header-view :catologModalFunc="catologModalFunc" :catologModal="catologModal" :loginModal="loginModal" :favourite_products="getFavourites.length" :loginModalFunc="loginModalFunc"></header-view>
    <basket-modal-view v-if="basketModal" :getFavourites="getFavourites" :toFavourites="toFavourites" :setFavouritedProducts="setFavouritedProducts" @click="closeModal"></basket-modal-view>
    <div class="carusel_modal" v-if="historyModal" @click="closeModal">
      <div class="confirm_modal" @click.stop>
        <div class="mb-4 text-start">
          <div class="text-center">
              <span data-aos="zoom-in" data-aos-duration="444">
              {{$t('Do you wish to delete all histories')}}
              </span>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <button data-aos="zoom-in" data-aos-duration="444" class="btn btn-danger" @click="closeModal">{{$t('No')}}</button>
          <button data-aos="zoom-in" data-aos-duration="444" class="btn btn-success" @click="deleteHistory">{{$t('Yes')}}</button>
        </div>
      </div>
    </div>

    <section class="mt-5">
      <div class="container d-flex">
        <ul class="d-flex" style="margin-left: -35px;">
          <li class="d-flex ms-1">
            <router-link data-aos="zoom-in" data-aos-duration="444" to="/" style="text-decoration: none; color: #000000;">
              {{$t("Home")}}
            </router-link>
            <div class="ms-1">
              <img data-aos="zoom-in" data-aos-duration="444" src="../assets/uploads/icons/arrow-left.svg" alt="arrow-left">
            </div>
          </li>
          <li class="d-flex ms-1">
            <router-link data-aos="zoom-in" data-aos-duration="444" to="/" style="text-decoration: none; color: #000000;">
              {{$t("your personal account")}}
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div class="container d-flex profile_data-adaptive">
        <personal-info-menus-view :current_menu="current_menu"></personal-info-menus-view>
        <div class="personal_info_content">
          <div class="d-flex flex-column">
            <personal-information-view></personal-information-view>
            <last-seen-view :historyDelete="historyDelete"></last-seen-view>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container detail_container mt-5" style="height: 464px">
        <h3 data-aos="zoom-in" data-aos-duration="444" class="comment_title pt-4">{{$t("You may like")}}</h3>
        <swiper-product></swiper-product>
      </div>
    </section>
    <footer-view></footer-view>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from "vuex"
export default {
  computed:{
    ...mapState(["backgroundModal", "catologModal", "loginModal", "historyModal", "basketModal"]),
    ...mapGetters(["getFavourites"])
  },
  data(){
    return{
      name:"Elyor",
      current_menu: {page:'profile_seen'},
      isLoading:true,
    }
  },
  methods:{
    ...mapMutations(["catologModalFunc", "closeModal", "loginModalFunc", "historyDelete", "setFavouritedProducts", "toFavourites"]),
    deleteHistory(){
      if(localStorage.getItem('seen_products')){
        localStorage.removeItem('seen_products')
        location.reload()
      }
    }
  },
  mounted() {
    this.setFavouritedProducts()
    setTimeout(() => {
      this.isLoading = false; // Set to false when the loading is complete
    }, 400);
  }
}
</script>
<style>
.payment_method-body{
  text-align: start;
}
</style>