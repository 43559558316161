import HeaderView from '@/components/HeaderView'
import FooterView from "@/components/FooterView";
import SwiperCarusel from "@/components/SwiperCarusel";
import ProductsView from "@/components/ProductsView";
import SwiperProduct from "@/components/SwiperProduct";
import ThumbnailSwiper from "@/components/ThumbnailSwiper";
import BackgroundTransparentView from "@/components/BackgroundTransparentView";
import SeenProductView from "@/components/SeenProductView";
import personalInfoMenusView from "@/components/PersonalInfoMenusView";
import personalInformationView from "@/components/PersonalInformationView";
import profileInformation from "@/components/ProfileInformation";
import CouponProductView from "@/components/CouponProductView";
import CouponsProductView from "@/components/CouponsProductView";
import OrderProductView from "@/components/OrderProductView";
import LastSeenView from "@/components/LastSeenView";
import BankProductCardView from "@/components/BankProductCardView";
import NotificationDataView from "@/components/NotificationDataView";
import SwiperBestProducts from "@/components/SwiperBestProducts";
import CatologModal from "@/components/CatologModal";
import CategoryDesktop from "@/components/CategoryDesktop";
import ProductSeen from "@/components/ProductSeen";
import BasketModalView from "@/components/BasketModalView";

export default [
    HeaderView,
    FooterView,
    SwiperCarusel,
    ProductsView,
    SwiperProduct,
    ThumbnailSwiper,
    BackgroundTransparentView,
    SeenProductView,
    personalInfoMenusView,
    personalInformationView,
    profileInformation,
    CouponProductView,
    CouponsProductView,
    OrderProductView,
    LastSeenView,
    BankProductCardView,
    NotificationDataView,
    SwiperBestProducts,
    CatologModal,
    CategoryDesktop,
    ProductSeen,
    BasketModalView,
]