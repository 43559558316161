<template>
  <background-transparent-view :closeModal="closeModal" :backgroundModal="backgroundModal"></background-transparent-view>
  <div v-if="isLoading" class="d-flex align-items-center" style="height: 100%">
    <div class="loading-spinner"></div>
  </div>
  <div v-else class="container-fluid">
    <header-view @filteredProducts="filteredProductsFunc" :catologModalFunc="catologModalFunc"  :catologModal="catologModal" :loginModal="loginModal" :favourite_products="getFavourites.length" :loginModalFunc="loginModalFunc"></header-view>
    <basket-modal-view v-if="basketModal" :getFavourites="getFavourites" :toFavourites="toFavourites" :setFavouritedProducts="setFavouritedProducts" @click="closeModal"></basket-modal-view>
    <section>
      <div class="container-fluid slick_sp mt-4 mb-4">
        <div class="splide__track">
          <div class="splide__list">
            <swiper-carusel></swiper-carusel>
          </div>
        </div>
      </div>
    </section>
    <section class="info">
      <div class="container">
        <div class="info_dektop">
          <div class="info__content d-flex">
            <div class="info__block d-flex">
              <img data-aos="zoom-in" data-aos-duration="444" src='../assets/uploads/icons/clock.svg' alt="clock" class="info__img" />
              <div class="info__desc">
                <h3 data-aos="zoom-in" data-aos-duration="444" class="info__title">{{$t('Service 24/7')}}</h3>
                <p data-aos="zoom-in" data-aos-duration="444" class="info__txt">{{$t('24/7 online shopping')}}</p>
              </div>
            </div>
            <div class="info__block d-flex">
              <img data-aos="zoom-in" data-aos-duration="444" src='../assets/uploads/icons/truck.svg' alt="truck" class="info__img" />
              <div class="info__desc">
                <h3 data-aos="zoom-in" data-aos-duration="444" class="info__title">{{$t('Fast delivery')}}</h3>
                <p data-aos="zoom-in" data-aos-duration="444" class="info__txt">{{$t('Fast delivery in Tashkent')}}</p>
              </div>
            </div>
            <div class="info__block d-flex">
              <img data-aos="zoom-in" data-aos-duration="444" src='../assets/uploads/icons/home-2.svg' alt="home" class="info__img" />
              <div class="info__desc">
                <h3 data-aos="zoom-in" data-aos-duration="444" class="info__title">{{$t('Pick-up point')}}</h3>
                <p data-aos="zoom-in" data-aos-duration="444" class="info__txt">{{$t('Convenient pick-up points')}}</p>
              </div>
            </div>
            <div class="info__block d-flex">
              <img data-aos="zoom-in" data-aos-duration="444" src='../assets/uploads/icons/card-tick.svg' alt="card" class="info__img" />
              <div class="info__desc">
                <h3 data-aos="zoom-in" data-aos-duration="444" class="info__title">{{$t('Online payment')}}</h3>
                <p data-aos="zoom-in" data-aos-duration="444" class="info__txt">{{$t('Convenient online payment')}}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img data-aos="zoom-in" data-aos-duration="444" class="info_mobile" src="../assets/uploads/images/Frame44.svg" alt="Frame">
          <img data-aos="zoom-in" data-aos-duration="444" class="info_mobile2" src="../assets/uploads/images/Frame71.svg" alt="Frame">
        </div>
      </div>
    </section>

    <section class="categories">
      <category-desktop></category-desktop>

<!--      <div class="container">-->
<!--        <img class="catogiro_mobile" src="../assets/uploads/images/Group50.svg" alt="Group">-->
<!--      </div>-->

<!--      <div class="container">-->
<!--        <img id="image" class="catogiro_mobile2" src="../assets/uploads/images/Group36.svg" alt="Group">-->
<!--        <div id="loader" class="loader"></div>-->
<!--      </div>-->
    </section>
    <section>
      <div class="container">
        <h3 data-aos="zoom-in" data-aos-duration="444" class="recomidation_text-big mt-5">{{$t('Products')}}</h3>
        <div v-if="getAll">
          <div class="row d-flex justify-content-center" v-if="getFilteredProducts">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6" v-for="(product, index) in getFilteredProducts" :key="index" style="height: 394px">
              <a @click="$router.push('/show-detail/'+product.id)" id="card_like">
                <product-view :basket="product" @mouseenter="showBasketFilteredProducts(index)"  @mouseleave="fromBasketFilteredProducts(index)"></product-view>
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row d-flex justify-content-center" v-if="paginatedItems">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6" v-for="(product, index) in paginatedItems" :key="index" style="height: 394px">
              <a @click="$router.push('/show-detail/'+product.id)" id="card_like">
                <product-view :basket="product" @mouseenter="showBasket(index)"  @mouseleave="fromBasket(index)"></product-view>
              </a>
            </div>
          </div>
          <div v-if="totalPages > 1">
            <button class="btn btn-success color_white me-1" @click="prevPage" :disabled="currentPage == 1">{{$t('Previous')}}</button>
            <button class="btn btn-success color_white me-1" @click="digitPage(1)" :disabled="currentPage == 1">1</button>
            <span v-for="(page, index) in pageNumbers" @click="digitPage(page)" :key="index">
              <button v-if="page != '...'" class="btn btn-success color_white me-1" :disabled="currentPage == page">
              {{ page }}</button>
              <span class="color_blue" v-else> . . . </span>
            </span>
            <button class="btn btn-success color_white me-1" @click="digitPage(totalPages)" :disabled="currentPage == totalPages">{{totalPages}}</button>
            <button class="btn btn-success color_white" @click="nextPage" :disabled="currentPage == totalPages">{{$t('Next')}}</button>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button @click="viewAll" class="show_more-rec" data-aos="zoom-in" data-aos-duration="444">{{$t('View all')}} ↓</button>
          </div>
        </div>
      </div>
    </section>
    <section class="offers mt-5">
      <div class="container">
        <h2 data-aos="zoom-in" data-aos-duration="444" class="offers__title">{{$t('First time here?')}}</h2>
        <p data-aos="zoom-in" data-aos-duration="444" class="offers__txt">{{$t('Leave your email and receive great offers!')}}</p>
        <div class="offers__block">
          <input data-aos="zoom-in" data-aos-duration="444" type="mail" class="offers__input" :placeholder="$t('Enter E-mail')" />
          <img data-aos="zoom-in" data-aos-duration="444" src='../assets/uploads/images/Arrow4.svg' alt="arrow" class="offers__img" />
          <button data-aos="zoom-in" data-aos-duration="444" class="offers__btn">{{$t('Subscribe')}}</button>
        </div>
      </div>
    </section>
    <footer-view></footer-view>
  </div>
</template>
<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import router from "@/router";
export default {
  computed:{
    ...mapState([
      'backgroundModal',
      'catologModal',
      'loginModal',
      "basketModal"
    ]),
    ...mapGetters([
      'basket',
      'getProductsBySubCategories',
      'getFavourites'
    ]),
    getFilteredProducts(){
      if(this.filteredProducts != ''){
        return this.filteredProducts
      }else{
        if(this.getProductsBySubCategories != undefined && this.getProductsBySubCategories != null){
          if(this.getProductsBySubCategories.length > 0){
            return this.getProductsBySubCategories[0].products
          }else{
            return []
          }
        }else{
          return []
        }
      }
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.getFilteredProducts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.getFilteredProducts.length / this.itemsPerPage);
    },
    pageNumbers(){
      this.getDigitPages()
      return this.threePages
    }
  },
  data(){
    return {
      name:'Elyor',
      router_id:router.currentRoute.value.params.id,
      isLoading:true,
      filteredProducts:'',
      currentPage: 1,
      itemsPerPage: 10,
      threePages:[],
      getAll:false
    }
  },
  methods:{
    ...mapMutations(['catologModalFunc', 'closeModal', 'loginModalFunc', 'setFavouritedProducts', "toFavourites"]),
    ...mapActions(['GETPRODUCTSBYSUBCATEGORIES']),
    showBasket(i){
      if(this.paginatedItems[i].basket_button == false){
        this.paginatedItems[i].basket_button = true;
      }
    },
    fromBasket(i){
      if(this.paginatedItems[i].basket_button == true){
        this.paginatedItems[i].basket_button = false;
      }
    },
    showBasketFilteredProducts(i){
      if(this.getFilteredProducts[i].basket_button == false){
        this.getFilteredProducts[i].basket_button = true;
      }
    },
    fromBasketFilteredProducts(i){
      if(this.getFilteredProducts[i].basket_button == true){
        this.getFilteredProducts[i].basket_button = false;
      }
    },
    filteredProductsFunc(allProducts){
      if(allProducts != undefined && allProducts != null && allProducts != ''){
        this.filteredProducts = allProducts
      }else{
        this.filteredProducts = ''
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
      this.getDigitPages()
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
      this.getDigitPages()
    },
    digitPage(digitPage){
      this.currentPage = parseInt(digitPage)
      this.getDigitPages()
    },
    getDigitPages(){
      this.threePages = []
      if(this.totalPages > 0){
        if(parseInt(this.currentPage)-2 > 1){
          this.threePages.push('...')
        }
        if(parseInt(this.currentPage)-1 > 1){
          this.threePages.push(parseInt(this.currentPage)-1)
        }
        if(1 < parseInt(this.currentPage) && parseInt(this.currentPage) < parseInt(this.totalPages)){
          this.threePages.push(parseInt(this.currentPage))
        }
        if(parseInt(this.totalPages) > parseInt(this.currentPage)+1){
          this.threePages.push(parseInt(this.currentPage)+1)
        }
        if(parseInt(this.totalPages) > parseInt(this.currentPage)+2){
          this.threePages.push('...')
        }
      }
    },
    viewAll(){
      this.getAll = true
      this.totalPages = 1
    }
  },
  mounted() {
    this.setFavouritedProducts()
    this.GETPRODUCTSBYSUBCATEGORIES()
    setTimeout(() => {
      this.isLoading = false; // Set to false when the loading is complete
    }, 400);
  },
}
</script>
<style>
.basket_background{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.to_basket{
  border:0;
  background-color: #8DC73F;
  color:white;
  border-radius: 4px;
  padding: 4px;
}
#card_like{
  margin: 10px 15px 20px 15px;
  display: flex;
  justify-content: center;
}
</style>