<template>
  <background-transparent-view :backgroundModal="backgroundModal" :closeModal="closeModal"></background-transparent-view>
  <div v-if="isLoading" class="d-flex align-items-center" style="height: 100%">
    <div class="loading-spinner"></div>
  </div>
  <div v-else>
    <header-view :catologModalFunc="catologModalFunc"  :loginModal="loginModal" :loginModalFunc="loginModalFunc" :catologModal="catologModal" :favourite_products="favourites.length"></header-view>
    <basket-modal-view v-if="basketModal" :favourite_page="favourite_page" :getFavourites="getFavourites" :toFavourites="toFavourites" :setFavouritedProducts="setFavouritedProducts" @click="closeModal"></basket-modal-view>
    <section class="mt-4">
      <div class="container d-flex justify-content-between">
        <div class="d-flex">
          <h1 class="basket_big-text" data-aos="zoom-in" data-aos-duration="444">{{$t('Favorites')}}</h1>
          <h3 class="basket__product-count ms-3" style="margin-right: 3rem; margin-top: 1rem;" data-aos="zoom-in" data-aos-duration="444">({{favourites.length}} {{$t('goods')}})</h3>
        </div>
        <div class="d-flex">
          <div class="d-flex header_bottom_text" style="margin-left: 3rem;">
            <img style="width: 20px; height: 20px;" data-aos="zoom-in" data-aos-duration="444" class="mt-3 send_and_trash-btn ms-3 me-2" src="../assets/uploads/icons/share.svg" alt="share">
            <h4 data-aos="zoom-in" data-aos-duration="444" class="basket_small-text">{{$t('Share')}}</h4>
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="row">
        <div v-if="favourites != undefined && favourites.length == 0" class="col-lg-5 col-md-12 favourites_product-card mt-4">
          <span>{{$t('No products')}}</span>
        </div>
        <div class="col-lg-6 col-md-12 mt-4" v-for="(product, index) in favourites" :key="index">
          <div class="favourites_product-card">
            <div class="row">
              <div class="col-7 col-md-7">
                <a @click="$router.push('/show-detail/'+product.id)" style="text-decoration: none; width: 100%" class="d-flex">
                  <div v-if="product.images.length>0" class="save_a_like-image" :style="'background-image: url('+ product.images[0] +')'"></div>
                  <div class="ms-2 favorites_product_text" style="margin-top: 1.5rem;">
                    <p v-if="product.name" class="favourites_title titile_favorit">
                      <b v-if="product.name.length>34">{{product.name.substring(0, 34)}}...</b>
                      <b v-else>{{product.name}}</b>
                    </p>
                    <p v-if="product.company" class="favourites_title" style="opacity: 0.5; margin-top: -10px;">
                      <span v-if="product.company.length>10">{{product.company.substring(0, 10)}}...</span>
                      <span v-else>{{product.company}}</span>
                    </p>
                    <p data-aos="zoom-in" data-aos-duration="444" class="favourites_title" style="opacity: 0.5; margin-top: -10px;" v-if="parseInt(product.price)>parseInt(product.sum)">
                      <del>{{parseInt(product.price)}} {{$t('Sum')}}</del>
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-5 col-md-5">
                <div style="display: flex; flex-direction: column; justify-content: space-between" class="favourite_botttom">
                  <div class="d-flex mt-4 justify-content-center">
                    <h3 data-aos="zoom-in" data-aos-duration="444" class="favourites_price" :sum="product.sum">{{product.sum}} {{$t('sum')}}</h3>
                  </div>
                  <div class="like_and_trash" style="margin-top: 80px; position: relative;">
                    <div class="d-flex">
                      <button class="favourites" @click="fromFavourite(index)">
                        <img data-aos="zoom-in" data-aos-duration="444" width="20" height="20" src="../assets/uploads/icons/heart.svg" alt="">
                      </button>
<!--                      <a @click="$router.push('/show-detail/'+product.id)" style="text-decoration: none;" data-aos="zoom-in" data-aos-duration="444" class="add_to_card">{{$t('Add to cart')}}</a>-->
                      <a @click="openBasketModal(product)" style="text-decoration: none;" data-aos="zoom-in" data-aos-duration="444" class="add_to_card">{{$t('Add to cart')}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container detail_container mt-5" style="height: 464px">
        <h3 data-aos="zoom-in" data-aos-duration="444" class="comment_title pt-4">{{$t('You may like')}}</h3>
        <swiper-product></swiper-product>
      </div>
    </section>
    <footer-view></footer-view>
  </div>
</template>
<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import axios from "axios";
import {defaultLocale} from "@/i18n";
export default {
  computed:{
    ...mapState(['backgroundModal', 'catologModal', 'loginModal', "basketModal"]),
    ...mapGetters(['getSeenProducts', "getFavourites"])
  },
  data(){
    return{
      favourites:[],
      isLoading:true,
      all_sum:0,
      favourite_page:true,
      language:localStorage.getItem('lang')||defaultLocale
    }
  },
  methods:{
    ...mapMutations(["toFavourites", "setFavouritedProducts", "openBasketModal"]),
    ...mapActions(['GETSEENPRODUCTS']),
    showBasket(i){
      if(this.basket[i].basket_button == false){
        this.basket[i].basket_button = true;
      }
    },
    fromBasket(i){
      if(this.basket[i].basket_button == true){
        this.basket[i].basket_button = false;
      }
    },
    async takeFavouriteProducts(){
      // if(localStorage.getItem('favourite_product')){
      //   this.favourites = JSON.parse(localStorage.getItem('favourite_product'))
      // }
      // let all_sum = 0;
      // for(let i = 0; i < this.favourites.length; i++){
      //   all_sum = all_sum + this.favourites[i].sum
      // }
      // this.all_sum = all_sum
      this.all_sum = 0
      let selected_products_id = []
      this.favourite_products_data = []
      if(localStorage.getItem('favourite_product')){
        this.favourite_products_data = JSON.parse(localStorage.getItem('favourite_product'))
        selected_products_id = JSON.parse(localStorage.getItem('favourite_product')).map(item => item.id)
      }
      try {
        this.favourites = await this.getFavouriteProduct(selected_products_id)
        console.log(this.favourites)
      }catch (e) {
        console.log(e)
      }
      for(let i=0; i<this.favourites.length; i++){
        for(let j=0; j<this.favourite_products_data.length; j++) {
          if (this.favourite_products_data[j].id == this.favourites[i].id) {
            this.all_sum = this.all_sum + parseInt(this.favourites[i].sum)
          }
        }
      }
    },
    async getFavouriteProduct(selected_products_id){
      try{
        const res = await axios.post(process.env.VUE_APP_SERVER+'/get-favourite-products',
            {
              selected_products_id: selected_products_id,
            },
            {
              headers:{
                'language': this.language,
              }
            })
        return res.data.data
      }catch (e) {
        console.log(e)
      }
    },
    async getCharacterizedProduct(selected_products_id){
      try{
        const res = await axios.post(process.env.VUE_APP_SERVER+'/get-characterized-product',
            {'selected_products_id':selected_products_id})
        return res.data.data
      }catch (e) {
        console.log(e)
      }
    },
    ...mapMutations(['closeModal', 'catologModalFunc', 'loginModalFunc']),
    fromFavourite(index){
      this.favourites.splice(index, 1)
      if(localStorage.getItem('favourite_product')){
        localStorage.removeItem('favourite_product')
      }
      localStorage.setItem('favourite_product', JSON.stringify(this.favourites))
      this.$toast.warning(this.$t('Product deleted from favourites !'));
    },
  },
  mounted(){
    this.setFavouritedProducts()
    this.GETSEENPRODUCTS()
    this.takeFavouriteProducts()
    setTimeout(() => {
      this.isLoading = false; // Set to false when the loading is complete
    }, 400);
  }
}
</script>
<style>
.save_a_like-image{
  height: 160px;
}
.favourites{
  width: 24px;
  height: 24px;
  background-color: white;
}
.add_to_card{
  text-align: center;
}
.favorites_total{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.favourites_product-card{
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 780px) {
  .save_a_like-image {
    width: 144px;
  }
}
@media only screen and (min-width: 401px) {
  .favorites_product_text{
    width: 74%;
  }
}
@media only screen and (max-width: 400px) {
  .favorites_product_text{
    width: 80px;
  }
  .row{
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .save_a_like-image {
    width: 124px;
  }
  .favourites_product-card{
    padding: 6px !important;
  }
}
.favourites_product-card .row{
  width: 100%;
}
.favourites_product-card .col-5{
  display: flex;
  justify-content: end;
}
</style>