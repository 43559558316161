<template>
  <background-transparent-view :closeModal="closeModal" :backgroundModal="backgroundModal"></background-transparent-view>
  <div v-if="isLoading" class="d-flex align-items-center" style="height: 100%">
    <div class="loading-spinner"></div>
  </div>
  <div v-else class="container-fluid">
    <header-view :catologModalFunc="catologModalFunc" :catologModal="catologModal" :loginModal="loginModal" :favourite_products="getFavourites.length" :loginModalFunc="loginModalFunc"></header-view>
    <basket-modal-view v-if="basketModal" :getFavourites="getFavourites" :toFavourites="toFavourites" :setFavouritedProducts="setFavouritedProducts" @click="closeModal"></basket-modal-view>
    <section class="mt-5">
      <div class="container d-flex">
        <ul class="d-flex" style="margin-left: -35px;">
          <li class="d-flex ms-1">
            <router-link data-aos="zoom-in" data-aos-duration="444" to="/" style="text-decoration: none; color: #000000;">
              {{$t("Home")}}
            </router-link>
            <div class="ms-1" data-aos="zoom-in" data-aos-duration="444">
              <img src="../assets/uploads/icons/arrow-left.svg" alt="arrow-left">
            </div>
          </li>
          <li class="d-flex ms-1">
            <router-link to="/" data-aos="zoom-in" data-aos-duration="444" style="text-decoration: none; color: #000000;">
              {{$t("your personal account")}}
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section>
      <div class="container d-flex notif_fat">
        <personal-info-menus-view :current_menu="current_menu" :profile_notif_class="profile_notif_class"></personal-info-menus-view>
            <notification-data-view></notification-data-view>
      </div>
    </section>

    <section>
      <div class="container detail_container mt-5" style="height: 464px">
        <h3 data-aos="zoom-in" data-aos-duration="444" class="comment_title pt-4">{{$t("You may like")}}</h3>
        <swiper-product></swiper-product>
      </div>
    </section>
    <footer-view></footer-view>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from "vuex"
export default {
  computed:{
    ...mapState(["backgroundModal", "catologModal", "loginModal", "basketModal"]),
    ...mapGetters(["getFavourites"])
  },
  data(){
    return{
      name:"Elyor",
      current_menu: {page:'profile_notification'},
      profile_notif_class:"profile_notif",
      isLoading:true
    }
  },
  methods:{
    ...mapMutations(["catologModalFunc", "closeModal", "loginModalFunc", "toFavourites", "setFavouritedProducts"])
  },
  mounted() {
    this.setFavouritedProducts()
    setTimeout(() => {
      this.isLoading = false; // Set to false when the loading is complete
    }, 400);
  }
}
</script>