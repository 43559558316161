<template>
  <div class="favourites_product-card favourites_product-card3 d-flex mt-4">
    <div class="mt-3 margin-profile" v-if="getUser">
      <h3 data-aos="zoom-in" data-aos-duration="444" class="hello_profile">{{$t('Hello')}} {{getUser.first_name}}!</h3>
      <p data-aos="zoom-in" data-aos-duration="444" class="welcome_profile">{{$t('Welcome')}} <br> {{$t('to')}} <b>{{$t('your personal account')}}</b></p>
    </div>
    <div class="profile_balance d-flex align-items-center">
      <div class="profile_balance_content">
        <div>
          <p data-aos="zoom-in" data-aos-duration="444" class="you_ballance">{{$t('Your points')}}</p>
        </div>
        <div class="profile_balance_price">
          <p data-aos="zoom-in" data-aos-duration="444" class="balance_text-profile">{{$t('Balance:')}}</p>
          <div class="profile_balance_icon" style="">
            <img data-aos="zoom-in" data-aos-duration="444" class="group37_icon" src="../assets/uploads/icons/Group37.svg" alt="icon">
            <h3 data-aos="zoom-in" data-aos-duration="444" class="ballance">100 500</h3>
          </div>
        </div>
      </div>
      <div>
        <img data-aos="zoom-in" data-aos-duration="444" class="ballance_image-bg" src="../assets/uploads/icons/Union.svg" alt="image">
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name:'personal-information-view',
  computed:{
    ...mapGetters(['getUser']),
  },
  methods:{
  ...mapActions(['GETUSER']),
  },
  mounted() {
    this.GETUSER()
  }
}
</script>
<style scoped>
.favourites_product-card{
  text-align: start;
  justify-content: space-between !important;
}
</style>