<template>
  <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css">
  <background-transparent-view :closeModal="closeModal" :backgroundModal="backgroundModal"></background-transparent-view>
  <div v-if="isLoading" class="d-flex align-items-start" style="height: 100%">
    <div class="loading-spinner"></div>
  </div>
  <div v-else class="container-fluid">
    <div class="carusel_modal" v-if="createAddressModal" @click="closeModal">
      <div class="map_content" @click.stop>
          <form id="custCarousel" class="carousel slide" @submit="saveAddress">
            <div class="carousel-inner">
              <select class="form-control" v-model="region" @change="selectRegion" required>
                <option :value="emtpy_string" disabled selected>{{$t('Select region')}}</option>
                <option v-for="(region_, index) in all_cities" :key="index" :value="region_">{{region_.name}}</option>
              </select>
              <select class="form-control mt-3" v-if="region.cities" v-model="city" @change="selectCity" required>
                <option :value="emtpy_string" disabled selected>{{$t('Select city')}}</option>
                <option v-for="(city_, index) in region.cities" :key="index" :value="city_">{{city_.name}}</option>
              </select>
              <input data-aos="zoom-in" data-aos-duration="444" type="text" class="form-control mt-3" v-if="city_selected" v-model="address" :placeholder="$t('Delivery address')">
            </div>
            <div>
              <span data-aos="zoom-in" data-aos-duration="444">{{$t('Lat')}}:{{LatLon[0]}}</span>&nbsp;&nbsp;
              <span data-aos="zoom-in" data-aos-duration="444">{{$t('Lng')}}:{{LatLon[1]}}</span>
            </div>
            <div class="LeafletMap" data-aos="zoom-in" data-aos-duration="444">
                <l-map
                    v-model="zoom"
                    v-model:zoom="zoom"
                    :center="LatLon"
                    @click="handleMapClick"
                >
                  <l-tile-layer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  ></l-tile-layer>
                  <l-marker :lat-lng="LatLon" draggable @moveend="log('moveend')">
                    <l-popup>
                      {{city.name}}
                    </l-popup>
                  </l-marker>
                </l-map>
            </div>
            <div data-aos="zoom-in" data-aos-duration="444" class="d-flex justify-content-end mt-2">
              <button class="submit-button" type="submit">{{$t('Save')}}</button>
            </div>
          </form>
        </div>
    </div>
    <div class="carusel_modal" v-if="editAddressModal" @click="closeModal">
      <div class="d-flex align-items-start" v-if="getAddressEdit">
        <div class="map_content" @click.stop>
          <form id="custCarousel" class="carousel slide" @submit="updateAddress">
            <div class="carousel-inner">
              <div v-if="Object.keys(getAddressEdit).length > 0 && getAddressEdit.region != undefined && getAddressEdit.region != null">
                <div class="d-none" v-if="!region_change">
                  {{editRegion = getAddressEdit.region}}
                  {{editRegion.cities = getAddressEdit.cities}}
                  <div v-if="!city_change">
                    {{edit_city = getAddressEdit.city}}
                  </div>
                </div>
              </div>
              <div>
                <select class="form-control"  v-model="editRegion" @change="editSelectRegion(getAddressEdit.region.id)" @click="editClickRegion" required>
                  <option :value="emtpy_string" disabled :selected="editRegion == undefined">{{$t('Select region')}}</option>
                  <option v-for="(region_, index) in all_cities" :key="index" :value="region_">{{region_.name}}</option>
                </select>
                <select class="form-control mt-3" v-if="editRegion.cities" v-model="edit_city" @change="editSelectCity" @click="editClickCity" required>
                  <option :value="emtpy_string" disabled :selected="editRegion.cities == undefined">{{$t('Select city')}}</option>
                  <option v-for="(city_, index) in editRegion.cities" :key="index" :value="city_">{{city_.name}}</option>
                </select>
              </div>
              <input type="text" class="form-control mt-3" :value="getAddressEdit.name" @change="editAddressChangingFunction" v-if="!editAddressChanged">
              <input type="text" class="form-control mt-3" v-else v-model="editAddress" :placeholder="$t('Delivery address')">
            </div>
            <div v-if="editLatLon.length>0">
              <span data-aos="zoom-in" data-aos-duration="444">{{$t('Lat')}}:{{editLatLon[0]}}</span>&nbsp;&nbsp;
              <span data-aos="zoom-in" data-aos-duration="444">{{$t('Lng')}}:{{editLatLon[1]}}</span>
            </div>
            <div v-else>
              <span data-aos="zoom-in" data-aos-duration="444">{{$t('Lat')}}:{{getAddressEdit.LatLon[0]}}</span>&nbsp;&nbsp;
              <span data-aos="zoom-in" data-aos-duration="444">{{$t('Lng')}}:{{getAddressEdit.LatLon[1]}}</span>
            </div>
            <div class="LeafletMap" v-if="editLatLon.length>0" data-aos="zoom-in" data-aos-duration="444">
              <l-map
                  v-model="edit_zoom"
                  v-model:zoom="edit_zoom"
                  :center="editLatLon"
                  @click="editHandleMapClick"
              >
                <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>
                <l-marker :lat-lng="editLatLon" draggable @moveend="log('moveend')">
                  <l-popup>
                    {{edit_city.name}}
                  </l-popup>
                </l-marker>
              </l-map>
            </div>
            <div class="LeafletMap" v-else data-aos="zoom-in" data-aos-duration="444">
              <l-map
                  v-model="edit_zoom"
                  v-model:zoom="getAddressEdit.zoom"
                  :center="getAddressEdit.LatLon"
                  @click="editHandleMapClick"
              >
                <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>
                <l-marker :lat-lng="getAddressEdit.LatLon" draggable @moveend="log('moveend')">
                  <l-popup>
                    {{getAddressEdit.name}}
                  </l-popup>
                </l-marker>
              </l-map>
            </div>
            <div class="d-flex justify-content-end mt-2">
              <button class="submit-button" type="submit">{{$t('Update')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="carusel_modal" v-if="deleteAddressModal" @click="closeModal">
      <div class="d-flex align-items-start" v-if="getAddressDelete">
        <div class="map_content" @click.stop>
          <div id="custCarousel" class="carousel slide">
            <div class="carousel-inner">
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{ getAddressDelete.region.name }}</span>&nbsp;
                <span data-aos="zoom-in" data-aos-duration="444">{{ getAddressDelete.city.name }} </span>&nbsp;
                <span data-aos="zoom-in" data-aos-duration="444">{{ getAddressDelete.name }} </span>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Are you sure you want to delete this address')}}</span>
              </div>
              <div class="d-flex justify-content-around mt-2">
                <button data-aos="zoom-in" data-aos-duration="444" class="btn btn-warning" @click="closeModal">{{$t('Cancell')}}</button>
                <button data-aos="zoom-in" data-aos-duration="444" class="btn btn-danger" @click="deleteAddress(getAddressDelete.id)">{{$t('Delete')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <header-view :catologModalFunc="catologModalFunc" :catologModal="catologModal" :loginModal="loginModal" :favourite_products="getFavourites.length" :loginModalFunc="loginModalFunc"></header-view>
    <basket-modal-view v-if="basketModal" :getFavourites="getFavourites" :toFavourites="toFavourites" :setFavouritedProducts="setFavouritedProducts" @click="closeModal"></basket-modal-view>
    <section class="mt-5">
      <div class="container d-flex">
        <ul class="d-flex" style="margin-left: -35px;">
          <li class="d-flex ms-1">
            <router-link data-aos="zoom-in" data-aos-duration="444" to="/" style="text-decoration: none; color: #000000;">
              {{$t("Home")}}
            </router-link>
            <div data-aos="zoom-in" data-aos-duration="444" class="ms-1">
              <img src="../assets/uploads/icons/arrow-left.svg" alt="arrow-left">
            </div>
          </li>
          <li class="d-flex ms-1">
            <router-link data-aos="zoom-in" data-aos-duration="444" to="/" style="text-decoration: none; color: #000000;">
              {{$t("your personal account")}}
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="container">
      <div class="container d-flex profile_data-adaptive">
        <personal-info-menus-view :current_menu="current_menu"></personal-info-menus-view>
        <div class="personal_info_content">
          <div class="d-flex flex-column">
            <personal-information-view></personal-information-view>
            <div class="seen_product-card mt-4">
              <div class="mt-3 margin-profile">
                <div class="d-flex justify-content-between">
                  <h3 data-aos="zoom-in" data-aos-duration="444" class="hello_profile">{{$t('Delivery address and pick-up points')}}</h3>
                  <button data-aos="zoom-in" data-aos-duration="444" class="button_location-green" @click="addLocation"><img src="../assets/uploads/icons/location-add.svg" alt="Location"></button>
                </div>
                <div v-if="my_addresses.length > 0">
                  <div v-for="(my_address, index) in my_addresses" :key="index">
                    <div class="row margin-profile mt-4">
                      <div class="col-8">
                        <p data-aos="zoom-in" data-aos-duration="444" class="addres_title ms-4 mt-3">{{$t('Адрес доставки:')}} <b>{{my_address.region.name+' '+my_address.city.name+' '+my_address.name }}</b></p>
                      </div>
                      <div class="col-4">
                        <button data-aos="zoom-in" data-aos-duration="444" class="button_location-blue mb-1" @click="editLocation(my_address)"><img src="../assets/uploads/icons/message-edit.svg" alt="Edit"></button>
                        <button data-aos="zoom-in" data-aos-duration="444" class="button_location-blue mb-1" @click="showMapFunc(index)" v-if="showed_map[index]"><i class="fas fa-eye"></i></button>
                        <button v-else class="button_location-blue" @click="showMapFunc(index)"><i class="fas fa-eye-slash"></i></button>
                        <button data-aos="zoom-in" data-aos-duration="444" class="button_location-red mb-1" @click="destroyLocation(my_address)"><img src="../assets/uploads/icons/trashWhite.svg" alt="Delete"></button>
                      </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="444" :class="'mt-4 profile_address_map '+map_display[index]" :id="'yandex_map_'+(index+1)"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container detail_container mt-5" style="height: 464px">
        <h3 data-aos="zoom-in" data-aos-duration="444" class="comment_title pt-4">{{$t("You may like")}}</h3>
        <swiper-product></swiper-product>
      </div>
    </section>
    <footer-view></footer-view>
  </div>
</template>
<script>
import {mapMutations, mapState, mapGetters, mapActions} from "vuex"
import axios from "axios";

import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import {defaultLocale} from "@/i18n";

export default {
  computed:{
    ...mapState(["backgroundModal", "catologModal", "loginModal", "createAddressModal", "editAddressModal", "deleteAddressModal", "basketModal"]),
    ...mapGetters(["getUser", "getAddressEdit", "getAddressDelete", "getFavourites"])
  },
  components:{
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data(){
    return{
      emtpy_string:{},
      name:"Elyor",
      current_menu: {page:'profile_address'},
      isLoading:true,
      all_cities:{},
      region:{},
      editRegion:{},
      city:{},
      edit_city:{},
      zoom: 7,
      edit_zoom: 7,
      LatLon: [41.311081, 69.240562],
      city_id:null,
      city_selected:false,
      edit_city_selected:false,
      address:'',
      getAddressEditId:'',
      region_change:false,
      region_changed:false,
      city_change:false,
      city_changed:false,
      edit_address_id:'',
      editAddress:'',
      editAddressChanged:false,
      showed_map:[true, true, true, true],
      map: null,
      map_display:['d-none', 'd-none', 'd-none', 'd-none'],
      my_addresses:[],
      editLatLon:[],
      language:localStorage.getItem('lang')||defaultLocale
    }
  },
  methods:{
    ...mapMutations(["catologModalFunc", "closeModal", "loginModalFunc", "addLocation", "editLocation", "toFavourites", "destroyLocation", "setFavouritedProducts"]),
    ...mapActions(['GETUSER']),
    async getCities(){
      try{
        const get_districts = await axios.get(process.env.VUE_APP_SERVER+'/get-districts',{
          headers:{
            'language':this.language
          }
        })
        this.all_cities = get_districts.data.data
      }catch (e) {
        console.log(e)
      }
    },
    editAddressChangingFunction(e){
      this.editAddress = e.target.value
      if(this.editAddressChanged == false){
        this.editAddressChanged = true
      }
    },
    setAddressLatLon(){
      this.edit_zoom = 15
    },
    showMapFunc(index){
      if(this.showed_map[index] == true){
        for(let i=0; i<4; i++){
          if(i != index){
            this.showed_map[i] = true
            this.map_display[i] = "d-none"
          }else{
            this.showed_map[i] = false
            this.map_display[i] = ""
          }
        }
      }else{
        this.showed_map[index] = true
        this.map_display[index] = "d-none"
      }
    },
    initMap() {
      if(this.my_addresses.length > 0){
        for (let i=0; i<this.my_addresses.length; i++){
          if(document.getElementById('yandex_map_'+(i+1)) != undefined && document.getElementById('yandex_map_'+(i+1)) != null){
            this.yandexFunc('yandex_map_'+(i+1), [this.my_addresses[i].latitude, this.my_addresses[i].longitude])
          }
        }
      }
    },
    yandexFunc(id, LatLon){
      let map = new window.ymaps.Map(id, {
        center: LatLon, // Initial map center coordinates
        zoom: 15, // Initial zoom level
      });
      let placemark = new window.ymaps.Placemark(LatLon, {}, {})
      map.controls.remove('geolocationControl'); // удаляем геолокацию
      map.controls.remove('searchControl'); // удаляем поиск
      map.controls.remove('trafficControl'); // удаляем контроль трафика
      // map.controls.remove('typeSelector'); // удаляем тип
      map.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
      map.controls.remove('zoomControl'); // удаляем контрол зуммирования
      map.controls.remove('rulerControl'); // удаляем контрол правил
      // map.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)
      map.geoObjects.add(placemark);
    },
    handleMapClick(event){
      this.LatLon = [event.latlng.lat, event.latlng.lng]
      this.city.lat = event.latlng.lat
      this.city.long = event.latlng.lng
    },
    editHandleMapClick(event){
      this.edit_zoom = 15
      this.editLatLon = [event.latlng.lat, event.latlng.lng]
    },
    editSelectCity(){
      this.city_changed = true
      this.edit_zoom = 15
      this.editLatLon = [this.edit_city.lat, this.edit_city.long]
      this.edit_city_selected = true
    },
    editSelectRegion(id){
      this.region_changed = true
      this.edit_address_id = id
      this.edit_city = {}
      this.edit_zoom = 7
      this.editLatLon = [this.editRegion.lat, this.editRegion.long]
      this.edit_city_selected = false
    },
    editClickRegion(){
      if(!this.region_changed){
        this.region_change = true
      }
    },
    editClickCity(){
      if(!this.city_changed){
        this.city_change = true
      }
    },
    selectCity(){
      this.zoom = 15
      this.LatLon = [this.city.lat, this.city.long]
      this.city_id = this.city.id
      this.city_selected = true
    },
    selectRegion(){
      this.city = {}
      this.zoom = 7
      this.LatLon = [this.region.lat, this.region.long]
      this.city_selected = false
    },
    async saveAddress(e){
      e.preventDefault()
      if(Object.keys(this.region).length == 0 || Object.keys(this.city).length == 0){
        if(Object.keys(this.region).length == 0){
          this.$toast.warning(this.$t('Select region'));
        }else if(Object.keys(this.city).length == 0){
          this.$toast.warning(this.$t('Select city'));
        }
      }else{
        try{
          const set_address = await axios.post(process.env.VUE_APP_SERVER+'/set-address', {
            'name':this.address,
            'city_id':this.city_id,
            'latitude':this.city.lat,
            'longitude':this.city.long,
            'user_id':this.getUser.id
          },{
            headers:{
              'Content-type':'multipart/form-data',
              'Authorization':`Bearer ${localStorage.getItem('user_token')}`
            }
          })
          if(set_address.data.message == "Number of your addresses has reached the limit"){
            this.$toast.warning(this.$t('Number of your addresses has reached the limit'))
          }else if(set_address.data.status == true){
            this.$toast.success(this.$t('Successfully added your address'));
            setTimeout(()=>{
              location.reload()
            }, 200)
          }else{
            this.$toast.success(this.$t('City or Region not found'));
          }
        }catch (e) {
          console.log(e)
        }
      }

    },
    async updateAddress(e){
      e.preventDefault()
      console.log(this.editAddress)
      if(this.editAddress == ''){
        this.editAddress = this.getAddressEdit.name
      }
      if(Object.keys(this.editRegion).length == 0 || Object.keys(this.edit_city).length == 0){
        if(Object.keys(this.editRegion).length == 0){
          this.$toast.warning(this.$t('Select region'));
        }else if(Object.keys(this.edit_city).length == 0){
          this.$toast.warning(this.$t('Select city'));
        }
      }else{
        try{
          if(this.editLatLon.length<=0){
            this.editLatLon = this.getAddressEdit.LatLon
          }
          const set_address = await axios.post(process.env.VUE_APP_SERVER+'/edit-address', {
            'id':this.getAddressEdit.id,
            'name':this.editAddress,
            'city_id':this.edit_city.id,
            'latitude':this.editLatLon[0],
            'longitude':this.editLatLon[1],
            'user_id':this.getUser.id
          },{
            headers:{
              'Content-type':'multipart/form-data',
              'Authorization':`Bearer ${localStorage.getItem('user_token')}`
            }
          })
          if(set_address.data.status == true){
            this.$toast.success(this.$t('Successfully updated'));
            setTimeout(()=>{
              location.reload()
            }, 200)
          }else{
            this.$toast.success(this.$t('City or Region not found'));
          }
        }catch (e) {
          console.log(e)
        }
      }
    },
    async deleteAddress(id){
      try{
        const response = await axios.post(process.env.VUE_APP_SERVER+'/destroy-address', {
          'id':id,
        },{
          headers:{
            'Content-type':'multipart/form-data',
            'language':this.language,
            'Authorization':`Bearer ${localStorage.getItem('user_token')}`
          }
        })
        if(response.data.status == true){
          if(response.data.message == 'prohibited'){
            this.$toast.warning(this.$t('You cannot delete this address because here is order associated with this address.'))
          }else{
            this.$toast.success(this.$t('Successfully updated'));
            setTimeout(()=>{
              location.reload()
            }, 200)
          }
        }else{
          this.$toast.success(this.$t('This your address aren\'t found'));
        }
      }catch (e) {
        console.log(e)
      }
    },
    async getAddress(){
      try{
        const addresses = await axios.get(process.env.VUE_APP_SERVER+'/get-address', {
          headers:{
            'Content-type':'Application/json',
            'language':this.language,
            'Authorization':`Bearer ${localStorage.getItem('user_token')}`
          }
        })
        this.my_addresses = addresses.data.data
      }catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.getAddress()
    this.setFavouritedProducts()
    setTimeout(() => {
      this.isLoading = false; // Set to false when the loading is complete
    }, 400);
    this.setAddressLatLon()
    this.getCities()
    this.GETUSER()
    setTimeout( ()=> {
      window.ymaps.ready(this.initMap)
    }, 4444);

  }
}
</script>
<style>


@media only screen and (max-width: 360px) {
  .LeafletMap{
    height: 194px !important;
    width: auto;
  }
}
.LeafletMap{
  height: 304px;
  width: auto;
}
.fa-eye, .fa-eye-slash{
  color:white;
}
.button_location-blue, .button_location-green, .button_location-red{
  transition:0.2s
}
.button_location-blue:hover, .button_location-green:hover, .button_location-red:hover{
  transform:scale(1.08);
}
.profile_address_map{
  width: auto;
  height: 344px;
}
.map_content{
  background-color: white;
  z-index: 6;
  border-radius: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
html {
  scroll-behavior: smooth;
}
</style>