<template>
  <ul class="nav nav-pills nav-justified mt-4">
    <li class="nav-item">
      <a class="nav-link success active" id="ordersOrderedMenu" @click="openOrder('ordersOrdered')">{{ $t('Ordered') }} <span class="badge badge-danger" v-if="my_orders['ordersOrdered'] && my_orders['ordersOrdered'].length>0">{{my_orders['ordersOrdered'].length}}</span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link success" id="ordersPerformedMenu"  @click="openOrder('ordersPerformed')">{{ $t('Performed') }} <span class="badge badge-danger" v-if="my_orders['ordersPerformed'] && my_orders['ordersPerformed'].length>0">{{my_orders['ordersPerformed'].length}}</span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link success" id="ordersCancelledMenu"  @click="openOrder('ordersCancelled')">{{ $t('Cancelled') }} <span class="badge badge-danger" v-if="my_orders['ordersCancelled'] && my_orders['ordersCancelled'].length>0">{{my_orders['ordersCancelled'].length}}</span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link success" id="ordersAcceptedMenu"  @click="openOrder('ordersAccepted')">{{ $t('Accepted') }} <span class="badge badge-danger" v-if="my_orders['ordersAccepted'] && my_orders['ordersAccepted'].length>0">{{my_orders['ordersAccepted'].length}}</span></a>
    </li>
  </ul>
  <div id="ordersOrdered">
    <div data-aos="zoom-in" data-aos-duration="444"  class="favourites_product-card history_profile-card d-flex mt-4 mt-1" v-for="order in my_orders['ordersOrdered']" :key="order.id">
      <div class="mt-1 ms-1">
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 20px;"><span class="me-1" style="opacity: 0.54;">{{order.order.code }}</span> {{$t('Order')}}
            <span v-if="parseInt(order.product_price)>0" class="color_green me-1 ms-1"> {{ order.product_price }} </span>  <span v-if="order.product_price" class="order_text">{{$t('Sum')}}</span>
          </h3>
          <h5 class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Created at')}}</span> <span style="opacity: 0.54">{{ order.order_created }}</span></h5>
          <h5 v-if="parseInt(order.order_coupon_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Coupon')}}</span> <span style="opacity: 0.54; color: red">{{ order.order_coupon_price }}</span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
          <h5 v-if="parseInt(order.discount_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Discount')}}</span> <span style="opacity: 0.54; color: red">{{ order.discount_price }}</span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12" v-for="(product, index) in order.products" :key="index">
            <div class="d-flex mt-2">
              <img :src="product.images[0]" alt="Rectangle" height="100" class="me-2">
              <div class="d-flex flex-column me-2">
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Name:')}} {{ product.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Sum:')}} {{ product[1]}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Size:')}} {{ product.size.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Color:')}} {{ product.color.name}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 14px;"><span style="font-size: 20px" class="me-1">{{$t(order.delivery_type)}} {{$t('address')}}</span> {{order.address}}</h3>
        </div>
        <div class="mt-4">
          <p class="hello_profile" style="opacity: 0.54; font-size: 20px;">{{$t('Delivery date:')}} {{order.order.delivery_date}}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="ordersPerformed" class="d-none">
    <div data-aos="zoom-in" data-aos-duration="444"  class="favourites_product-card history_profile-card d-flex mt-4 mt-1" v-for="order in my_orders['ordersPerformed']" :key="order.id">
      <div class="mt-1 ms-1">
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 20px;"><span class="me-1" style="opacity: 0.54;">{{order.order.code }}</span> {{$t('Order')}}
            <span v-if="parseInt(order.product_price)>0" class="color_green me-1 ms-1"> {{ order.product_price }} </span>  <span v-if="order.product_price" class="order_text">{{$t('Sum')}}</span>
          </h3>
          <h5 class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Created at')}}</span> <span style="opacity: 0.54">{{ order.order_created }}</span></h5>
          <h5 v-if="parseInt(order.order_coupon_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Coupon')}}</span> <span style="opacity: 0.54; color: red">{{ order.order_coupon_price }} </span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
          <h5 v-if="parseInt(order.discount_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Discount')}}</span> <span style="opacity: 0.54; color: red">{{ order.discount_price }}</span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12" v-for="(product, index) in order.products" :key="index">
            <div class="d-flex mt-2">
              <img :src="product.images[0]" alt="Rectangle" height="100" class="me-2">
              <div class="d-flex flex-column me-2">
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Name:')}} {{ product.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Sum:')}} {{ product[1]}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Size:')}} {{ product.size.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Color:')}} {{ product.color.name}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 14px;"><span style="font-size: 20px" class="me-1">{{$t(order.delivery_type)}} {{$t('address')}}</span> {{order.address}}</h3>
        </div>
        <div class="mt-4">
          <p class="hello_profile" style="opacity: 0.54; font-size: 20px;">{{$t('Delivery date:')}} {{order.order.delivery_date}}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="ordersCancelled" class="d-none">
    <div data-aos="zoom-in" data-aos-duration="444"  class="favourites_product-card history_profile-card d-flex mt-4 mt-1" v-for="order in my_orders['ordersCancelled']" :key="order.id">
      <div class="mt-1 ms-1">
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 20px;"><span class="me-1" style="opacity: 0.54;">{{order.order.code }}</span> {{$t('Order')}}
            <span v-if="parseInt(order.product_price)>0" class="color_green me-1 ms-1"> {{ order.product_price }} </span>  <span v-if="order.product_price" class="order_text">{{$t('Sum')}}</span>
          </h3>
          <h5 class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Created at')}}</span> <span style="opacity: 0.54">{{ order.order_created }}</span></h5>
          <h5 v-if="parseInt(order.order_coupon_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Coupon')}}</span> <span style="opacity: 0.54; color: red">{{ order.order_coupon_price }}</span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
          <h5 v-if="parseInt(order.discount_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Discount')}}</span> <span style="opacity: 0.54; color: red">{{ order.discount_price }}</span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12" v-for="(product, index) in order.products" :key="index">
            <div class="d-flex mt-2">
              <img :src="product.images[0]" alt="Rectangle" height="100" class="me-2">
              <div class="d-flex flex-column me-2">
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Name:')}} {{ product.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Sum:')}} {{ product[1]}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Size:')}} {{ product.size.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Color:')}} {{ product.color.name}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 14px;"><span style="font-size: 20px" class="me-1">{{$t(order.delivery_type)}} {{$t('address')}}</span> {{order.address}}</h3>
        </div>
        <div class="mt-4">
          <p class="hello_profile" style="opacity: 0.54; font-size: 20px;">{{$t('Delivery date:')}} {{order.order.delivery_date}}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="ordersAccepted" class="d-none">
    <div data-aos="zoom-in" data-aos-duration="444"  class="favourites_product-card history_profile-card d-flex mt-4 mt-1" v-for="order in my_orders['ordersAccepted']" :key="order.id">
      <div class="mt-1 ms-1">
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 20px;"><span class="me-1" style="opacity: 0.54;">{{order.order.code }}</span> {{$t('Order')}}
            <span v-if="parseInt(order.product_price)>0" class="color_green me-1 ms-1"> {{ order.product_price }} </span>  <span v-if="order.product_price" class="order_text">{{$t('Sum')}}</span>
          </h3>
          <h5 class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Created at')}}</span> <span style="opacity: 0.54">{{ order.order_created }}</span></h5>
          <h5 v-if="parseInt(order.order_coupon_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Coupon')}}</span> <span style="opacity: 0.54; color: red">{{ order.order_coupon_price }}</span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
          <h5 v-if="parseInt(order.discount_price, 10)>0" class="hello_profile" style="font-size: 16px;"> <span class="order_text">{{$t('Discount')}}</span> <span style="opacity: 0.54; color: red">{{ order.discount_price }}</span> <span class="sum_text"> {{$t('Sum')}}</span></h5>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12" v-for="(product, index) in order.products" :key="index">
            <div class="d-flex mt-2">
              <img :src="product.images[0]" alt="Rectangle" height="100" class="me-2">
              <div class="d-flex flex-column me-2">
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Name:')}} {{ product.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Sum:')}} {{ product[1]}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Size:')}} {{ product.size.name}}</h3>
                <h3 class="hello_profile" style="font-size: 16px;">{{$t('Color:')}} {{ product.color.name}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px;">
          <h3 class="hello_profile" style="font-size: 14px;"><span style="font-size: 20px" class="me-1">{{$t(order.delivery_type)}} {{$t('address')}}</span> {{order.address}}</h3>
        </div>
        <div class="mt-4">
          <p class="hello_profile" style="opacity: 0.54; font-size: 20px;">{{$t('Delivery date:')}} {{order.order.delivery_date}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {defaultLocale} from "@/i18n";
import axios from "axios";

export default {
  name:'order-product-view',
  data(){
    return{
      lang: (localStorage.getItem('lang')||defaultLocale),
      my_orders:[],
      language:localStorage.getItem('lang')||defaultLocale
    }
  },
  methods:{
    openOrder(status){
      if(document.getElementById(status).classList.contains('d-none')){
        document.getElementById(status).classList.remove("d-none");
      }
      if(!document.getElementById(status+'Menu').classList.contains('active')){
        document.getElementById(status+'Menu').classList.add("active");
      }
      let ordersOrdered = document.getElementById("ordersOrdered")
      let ordersPerformed = document.getElementById("ordersPerformed")
      let ordersCancelled = document.getElementById("ordersCancelled")
      let ordersAccepted = document.getElementById("ordersAccepted")
      let ordersOrderedMenu = document.getElementById("ordersOrderedMenu")
      let ordersPerformedMenu = document.getElementById("ordersPerformedMenu")
      let ordersCancelledMenu = document.getElementById("ordersCancelledMenu")
      let ordersAcceptedMenu = document.getElementById("ordersAcceptedMenu")
      switch (status) {
        case "ordersOrdered":
          if(!ordersPerformed.classList.contains('d-none')) {
            ordersPerformed.classList.add("d-none");
          }
          if(!ordersCancelled.classList.contains('d-none')) {
            ordersCancelled.classList.add("d-none");
          }
          if(!ordersAccepted.classList.contains('d-none')) {
            ordersAccepted.classList.add("d-none");
          }
          if(ordersPerformedMenu.classList.contains('active')) {
            ordersPerformedMenu.classList.remove("active");
          }
          if(ordersCancelledMenu.classList.contains('active')) {
            ordersCancelledMenu.classList.remove("active");
          }
          if(ordersAcceptedMenu.classList.contains('active')) {
            ordersAcceptedMenu.classList.remove("active");
          }
          break;
        case "ordersPerformed":
          if(!ordersOrdered.classList.contains('d-none')) {
            ordersOrdered.classList.add("d-none");
          }
          if(!ordersCancelled.classList.contains('d-none')) {
           ordersCancelled.classList.add("d-none");
          }
          if(ordersAccepted.classList.contains('d-none')) {
          ordersAccepted.classList.add("d-none");
          }
          if(ordersOrderedMenu.classList.contains('active')) {
            ordersOrderedMenu.classList.remove("active");
          }
          if(ordersCancelledMenu.classList.contains('active')) {
           ordersCancelledMenu.classList.remove("active");
          }
          if(ordersAcceptedMenu.classList.contains('active')) {
          ordersAcceptedMenu.classList.remove("active");
          }
          break;
        case "ordersCancelled":
          if(!ordersPerformed.classList.contains('d-none')) {
            ordersPerformed.classList.add("d-none");
          }
          if(!ordersOrdered.classList.contains('d-none')) {
            ordersOrdered.classList.add("d-none");
          }
          if(!ordersAccepted.classList.contains('d-none')) {
            ordersAccepted.classList.add("d-none");
          }
          if(ordersPerformedMenu.classList.contains('active')) {
            ordersPerformedMenu.classList.remove("active");
          }
          if(ordersOrderedMenu.classList.contains('active')) {
            ordersOrderedMenu.classList.remove("active");
          }
          if(ordersAcceptedMenu.classList.contains('active')) {
            ordersAcceptedMenu.classList.remove("active");
          }
          break;
        case "ordersAccepted":
          if(!ordersPerformed.classList.contains('d-none')) {
            ordersPerformed.classList.add("d-none");
          }
          if(!ordersCancelled.classList.contains('d-none')) {
            ordersCancelled.classList.add("d-none");
          }
          if(!ordersOrdered.classList.contains('d-none')) {
            ordersOrdered.classList.add("d-none");
          }
          if(ordersPerformedMenu.classList.contains('active')) {
            ordersPerformedMenu.classList.remove("active");
          }
          if(ordersCancelledMenu.classList.contains('active')) {
            ordersCancelledMenu.classList.remove("active");
          }
          if(ordersOrderedMenu.classList.contains('active')) {
            ordersOrderedMenu.classList.remove("active");
          }
          break;
      }
    },
    async getMyOrders(){
      try{
        const response = await axios.get(process.env.VUE_APP_SERVER+'/my-orders', {
          headers:{
            'Content-type':'Application/json',
            'language':this.language,
            'Authorization':`Bearer ${localStorage.getItem('user_token')}`,
          },
        })
        if(response.status == 200){
          this.my_orders = response.data.data
        }
      }catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.getMyOrders()
  }
}

</script>
<style scoped>
.badge-danger{
  background-color: red;
}
.favourites_product-card{
  text-align: start;
  justify-content: start !important;
}
.nav-pills .nav-link.success {
  color: black !important;
}
.nav-pills .nav-link.success:hover {
  background-color: #E2F1CF !important;
  color: black !important;
}
.nav-pills .nav-link.success.active {
  background-color: #8DC73F !important;
  color: #fff !important;
}
.color_green{
  color: #8DC73F;
}
.order_text{
  font-size: 16px;
  margin-right: 10px;
}
.sum_text{
  font-size: 14px;
  opacity: 0.54;
  margin-left: 7px;
}
</style>