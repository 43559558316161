<template>
  <div class="card_content like__cards"  data-aos="zoom-in"  data-aos-duration="444">
    <div v-if="basket.images.length>0" class="card_content_image justify-content-end" :style="'background-image: url('+basket.images[0]+')'">
      <div class="card_discount" v-if="basket.discount != null">{{'-'+basket.discount+'%'}}</div>
    </div>
    <div class="text-start discount_price">
      <del class="pt-2" v-if="parseInt(basket.price)>parseInt(basket.sum)">
        {{basket.price.toLocaleString('ru-Ru', { style: 'currency', currency: 'uzs', minimumFractionDigits: 0,
        maximumFractionDigits: 0 })}}
      </del>
    </div>
    <h3 class="comment_title pt-2">
      {{basket.sum.toLocaleString('ru-Ru', { style: 'currency', currency: 'uzs', minimumFractionDigits: 0,
      maximumFractionDigits: 0 })}}
    </h3>
    <p class="comment_text" style="white-space: nowrap">{{basket.name}}</p>
    <div class="d-flex favorites_fat-div">
      <div>
        <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
      </div>
      <p class="detail_star">{{ 5 }} {{$t('reviews')}}</p>
    </div>
    <transition name="basketButton" class="not_mobile_cart_button">
      <div class="basket_background" @click.stop v-if="basket.basket_button">
        <button class="to_basket" @click="openBasketModal(basket)">{{$t('Add to cart')}}</button>
      </div>
    </transition>
    <transition name="basketButton" class="mobile_cart_button">
      <div class="basket_background" @click.stop>
        <button class="to_basket" @click="openBasketModal(basket)">{{$t('Add to cart')}}</button>
      </div>
    </transition>
  </div>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  name:'product-seen',
  props:{
    basket:{
      type:Object,
      required:true
    },
  },
  methods: {
    ...mapMutations(["openBasketModal"]),
    toBasket(){
      window.scroll(200, 0);
    }
  },
}
</script>

<style>
.discount_price{
  height: 24px;
  color:black;
}
.discount_price>del{
  margin-left: 8px;
  opacity:0.4;
}
.card_content_image{
  width: 100%;
  display: flex;
  height: 200px;
  background-size: cover;
}
.card_discount{
  width: 44px;
  height:30px;
  margin: -6px -6px 0px 0px;
  display: flex;
  border-bottom-left-radius: 84%;
  border-bottom: solid 1px #F2A166;
  border-left: solid 1px #F2A166;
  justify-content: end;
  font-size: 14px;
  color:#F2A166;
  background-color:#FEF4EE;
}
.basketButton-enter-from{
  opacity: 0;
}
.basketButton-enter-to{
  opacity: 1;
}
.basketButton-enter-active{
  transition: all 0.1s ease !important;
}
.basketButton-leave-from{
  opacity: 1;
}
.basketButton-leave-to{
  opacity: 0;
}
.basketButton-leave-active{
  transition: all 0.1s ease !important;
}
.card_image_{
  border-radius: 4px;
}
.to_basket{
  width:auto;
}
@media (min-width: 1201px) {
  .mobile_cart_button{
    display: none;
  }
}
@media (max-width: 1200px) {
  .not_mobile_cart_button{
    display: none;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  .to_basket{
    font-size: 14px;
  }
}
@media (min-width: 400px) and (max-width: 599px) {
  .to_basket{
    font-size: 12px;
  }
}
@media (max-width: 399px) {
  .to_basket{
    font-size: 11px;
  }
}
</style>