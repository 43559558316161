<template>
<!--  <div class="card_content like__cards">-->
    <div class="card_content_image justify-content-end" :style="'background-image: url('+basket.images+')'">
      <div data-aos="zoom-in" data-aos-duration="444" class="card_discount" v-if="basket.discount != null">{{'-'+basket.discount+'%'}}</div>
    </div>
    <div class="text-start discount_price">
      <del data-aos="zoom-in" data-aos-duration="444" class="pt-2" v-if="parseInt(basket.price)>parseInt(basket.sum)">
        {{basket.price.toLocaleString('ru-Ru', { style: 'currency', currency: 'uzs',minimumFractionDigits: 0,
        maximumFractionDigits: 0 })}}
      </del>
    </div>
    <h3 data-aos="zoom-in" data-aos-duration="444" class="comment_title pt-2">
      {{basket.sum.toLocaleString('ru-Ru', { style: 'currency', currency: 'uzs',minimumFractionDigits: 0,
      maximumFractionDigits: 0 })}}
    </h3>
    <p data-aos="zoom-in" data-aos-duration="444" class="comment_text" style="white-space: nowrap">{{basket.name}}</p>
    <div class="d-flex favorites_fat-div">
      <div>
        <img data-aos="zoom-in" data-aos-duration="444" class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img data-aos="zoom-in" data-aos-duration="444" class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img data-aos="zoom-in" data-aos-duration="444" class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img data-aos="zoom-in" data-aos-duration="444" class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
        <img data-aos="zoom-in" data-aos-duration="444" class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
      </div>
      <p data-aos="zoom-in" data-aos-duration="444" class="detail_star">{{ 5 }} {{$t('reviews')}}</p>
    </div>
    <transition name="basketButton">
      <div class="basket_background" v-if="basket.basket_button">
        <button data-aos="zoom-in" data-aos-duration="444" class="to_basket" @click="toBasket">{{$t('Add to cart')}}</button>
      </div>
    </transition>
<!--  </div>-->
</template>
<script>
export default {
  name:'seen-product-view',
  props:{
    basket:{
      required:true
    }
  },
  methods: {
    toBasket(e) {
      e.preventDefault()
    },
  },
  mounted() {
    // console.log(this.basket)
  }
}
</script>
<style>
.card_image_{
  height: 117px;
}
</style>