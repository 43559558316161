<template>
  <div>
    <span>Three D</span>
  </div>
</template>
<script>
export default {
  data(){
    return{
      name:'Elyor'
    }
  },
  methods:{

  }
}
</script>