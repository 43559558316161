<template>
  <background-transparent-view :backgroundModal="backgroundModal" :closeModal="closeModal"></background-transparent-view>
  <div v-if="isLoading" class="d-flex align-items-center" style="height: 100%">
    <div class="loading-spinner"></div>
  </div>
  <div v-else>
    <header-view :catologModalFunc="catologModalFunc"  :loginModal="loginModal" :loginModalFunc="loginModalFunc" :catologModal="catologModal" :selected_products="selected_products.length" :favourite_products="getFavourites.length"></header-view>
    <basket-modal-view v-if="basketModal" :basket_page="basket_page" :getFavourites="getFavourites" :toFavourites="toFavourites" :setFavouritedProducts="setFavouritedProducts" @click="closeModal"></basket-modal-view>
      <div class="carusel_modal" v-if="checkoutModal" @click="closeModal">
        <div v-if="checkoutModal_">
          <div class="confirm_modal" @click.stop>
            <div class="mb-4 text-start">
              <div class="text-center">
              <span data-aos="zoom-in" data-aos-duration="444">
              {{$t('Do you confirm this order?')}}
              </span>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('User name')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{first_name}}</b>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('User phone')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{phone_number}}</b>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Price')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{all_sum + 18000 - selected_coupon.coupon_price}} {{$t('Sum')}}</b>
              </div>
              <div v-if="Object.keys(address).length > 0">
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Address')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{address.region.name+' '+address.city.name+' '+address.name }}</b>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Payment method')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{$t(payment)}}</b>
              </div>
<!--              <div v-if="Object.keys(card).length > 0">-->
<!--                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Card number')}}: </span>-->
<!--                <b data-aos="zoom-in" data-aos-duration="444">{{card.name+' '+card.card_number}}</b>-->
<!--              </div>-->
            </div>
            <div class="d-flex justify-content-around">
              <button class="btn btn-danger" @click="closeModal">{{$t('No')}}</button>
              <button class="btn btn-success" @click="confirmOrder">{{$t('Yes')}}</button>
            </div>
          </div>
        </div>
        <div v-if="!checkoutModal_">
          <div class="confirm_modal" @click.stop>
            <div class="mb-4 text-start">
              <div class="text-center">
              <span data-aos="zoom-in" data-aos-duration="444">
              {{$t('Order information')}}
              </span>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Order code')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{order_info.code}}</b>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Pick up time')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{order_info.pick_up_time}}</b>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Price')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{all_sum + 18000 - selected_coupon.coupon_price}} {{$t('Sum')}}</b>
              </div>
              <div v-if="Object.keys(address).length > 0">
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Address')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{order_info.address }}</b>
              </div>
              <div>
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('Payment method')}}: </span>
                <b data-aos="zoom-in" data-aos-duration="444">{{$t(payment)}}</b>
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <button class="btn btn-success" @click="confirmOrderInfoFunc">{{$t('Yes')}}</button>
            </div>
          </div>
        </div>
      </div>
      <section class="mt-5">
        <div class="container d-flex">
          <ul class="d-flex" style="margin-left: -35px;">
            <li class="d-flex ms-1">
              <router-link to="/" style="text-decoration: none; color: #000000;" data-aos="zoom-in" data-aos-duration="444">
                {{$t('Home')}}
              </router-link>
              <div class="ms-1" data-aos="zoom-in" data-aos-duration="444">
                <img src="../assets/uploads/icons/arrow-left.svg" alt="arrow-left">
              </div>
            </li>
            <li class="d-flex ms-1">
              <router-link to="/profile" style="text-decoration: none; color: #000000;" data-aos="zoom-in" data-aos-duration="444">
                {{$t('Personal Area')}}
              </router-link>
              <div class="ms-1" data-aos="zoom-in" data-aos-duration="444">
                <img src="../assets/uploads/icons/arrow-left.svg" alt="arrow-left">
              </div>
            </li>
            <li class="d-flex ms-1">
              <router-link to="/basket" style="text-decoration: none; color: #000000;" data-aos="zoom-in" data-aos-duration="444">
                {{$t('Basket')}}
              </router-link>
              <div class="ms-1" data-aos="zoom-in" data-aos-duration="444">
                <img src="../assets/uploads/icons/arrow-left.svg" alt="arrow-left">
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div class="container d-flex">
          <h1 class="basket_big-text" data-aos="zoom-in" data-aos-duration="444">{{$t('Basket')}}</h1>
          <h3 class="basket__product-count ms-3" style="margin-right: 2rem; margin-top: 0.2rem;" data-aos="zoom-in" data-aos-duration="444">({{selected_products.length}} {{$t('goods')}})</h3>
          <div class="d-flex header_bottom_text" style="margin-left: 2rem;">
            <img style="width: 20px; height: 20px;" class="mt-3 send_and_trash-btn ms-2 me-2" data-aos="zoom-in" data-aos-duration="444" src="../assets/uploads/icons/share.svg" alt="share">
            <h4 class="basket_small-text" data-aos="zoom-in" data-aos-duration="444">{{$t('Share')}}</h4>
          </div>
          <div class="d-flex ms-3">
            <img style="width: 20px; height: 20px;" class="mt-3 send_and_trash-btn ms-2 me-2" src="../assets/uploads/icons/trash.svg" alt="trash" data-aos="zoom-in" data-aos-duration="444">
            <h4 class="basket_small-text" data-aos="zoom-in" data-aos-duration="444">{{$t('Empty trash')}}</h4>
          </div>
        </div>
      </section>

      <section>
        <div class="container d-flex basket_father">
          <div class="me-4">
            <div class="d-flex flex-column">
              <div v-if="selected_products.length == 0" class="basket_products mt-4 d-flex align-items-center justify-content-center">
                <span data-aos="zoom-in" data-aos-duration="444">{{$t('No products')}}</span>
              </div>
              <div v-for="(product, index) in selected_products" :key="index" class="basket_products mt-4">
                <a @click="$router.push('/show-detail/'+product.product_id)" style="text-decoration: none;" class="d-flex align-items-center justify-content-around" data-aos="zoom-in" data-aos-duration="444">
                  <div class="save_a_like-image_basket" data-aos="zoom-in" data-aos-duration="444" :style="'background-image: url('+ product.images +')'"></div>
                  <div class="basket_products_content">
                    <p v-if="product.name" class="basket_title basket_title_padding">
                      <b v-if="product.name.length>20">{{product.name.substring(0, 20)}}...</b>
                      <b v-else>{{product.name}}</b>
                    </p>
                    <span v-if="product.company" class="basket_title" style="opacity: 0.5;">
                      <span v-if="product.company.length>10">{{product.company.substring(0, 10)}}...</span>
                      <span v-else>{{product.company}}</span>
                    </span>
                    <div class="d-flex">
                      <div v-if="!Array.isArray(product.color)" class="product_color" :style="'background-color:'+product.color.code"></div>
                      <div v-else class="text-start">
                        <img src="../assets/images/no_icon_red.png" class="product_color">
                      </div>
                      <div v-if="product.size">
                        <span class="basket_product_size">{{$t('Size')}}: {{product.size}}</span>
                      </div>
                    </div>
                  </div>
                  <div style="height: 100%">
                    <div>
                      <p data-aos="zoom-in" data-aos-duration="444" class="basket_title" style="opacity: 0.7;">{{product.sum}} {{$t('sum')}}</p>
                      <p data-aos="zoom-in" data-aos-duration="444" v-if="parseInt(product.price) > parseInt(product.sum)" class="basket_title" style="opacity: 0.3;"><del>{{product.price}} {{$t('sum')}}</del></p>
                    </div>
                  </div>
                </a>
                <div style="flex-direction: column;">
                  <div class="d-flex">
                    <button data-aos="zoom-in" data-aos-duration="444" class="basket_minus me-2" @click="minusFunc(index)">−</button>
                    <input data-aos="zoom-in" data-aos-duration="444" class="basket_number-input" type="text" :value="product.count" id="input"/>
                    <button data-aos="zoom-in" data-aos-duration="444" class="basket_minus ms-2 me-3" @click="plusFunc(index)">+</button>
                    <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_price">{{product.sum*product.count}} {{$t('sum')}}</h3>
                  </div>
                  <div class="basket_trash-btn" style="margin-top: 20px; position: relative; left: 90px;">
                    <h3 class="basket_price-mobile">{{product.sum*product.count}} {{$t('sum')}}</h3>
                    <div class="d-flex">
                      <div style="cursor: pointer;" id="activeDiv" @click="toFavourite(product)" class="me-2">
                        <svg data-aos="zoom-in" data-aos-duration="444" v-if="product.is_favourite == undefined || product.is_favourite == null || product.is_favourite != true" style="cursor: pointer;" width="20" height="20" id="activeSvg" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="activePath" d="M17.1582 4.14159C17.8915 4.96659 18.3332 6.04992 18.3332 7.24158C18.3332 13.0749 12.9332 16.5166 10.5165 17.3499C10.2332 17.4499 9.7665 17.4499 9.48317 17.3499C7.0665 16.5166 1.6665 13.0749 1.6665 7.24158C1.6665 4.66659 3.7415 2.58325 6.29984 2.58325C7.8165 2.58325 9.15817 3.31659 9.99984 4.44992C10.8415 3.31659 12.1915 2.58325 13.6998 2.58325" stroke="#8DC73F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg data-aos="zoom-in" data-aos-duration="444" v-else style="cursor: pointer;" width="20" height="20" id="activeSvg" viewBox="0 0 20 20" fill="#8DC73F" xmlns="http://www.w3.org/2000/svg">
                          <path id="activePath" d="M17.1582 4.14159C17.8915 4.96659 18.3332 6.04992 18.3332 7.24158C18.3332 13.0749 12.9332 16.5166 10.5165 17.3499C10.2332 17.4499 9.7665 17.4499 9.48317 17.3499C7.0665 16.5166 1.6665 13.0749 1.6665 7.24158C1.6665 4.66659 3.7415 2.58325 6.29984 2.58325C7.8165 2.58325 9.15817 3.31659 9.99984 4.44992C10.8415 3.31659 12.1915 2.58325 13.6998 2.58325" stroke="#8DC73F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                      <button data-aos="zoom-in" data-aos-duration="444" @click="deleteFunc(index)">
                        <img width="20" height="20" src="../assets/uploads/icons/trashRed.svg" alt="trash">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="basket_total mt-4 all_product" style="padding: 25px;">
            <div class="d-flex justify-content-between mt-1">
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all-price">{{ $t("Products price") }} <span style="opacity: 0.5">({{selected_products.length}})</span></h3>
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{all_price}} {{$t('Sum')}}</h3>
            </div>
            <div class="d-flex justify-content-between mt-1" v-if="selected_coupon.coupon.id != ''">
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{ $t("Coupon") }} "{{selected_coupon.coupon.name}}"</h3>
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{- selected_coupon.coupon_price}} {{$t('Sum')}}</h3>
            </div>
            <div class="d-flex justify-content-between mt-1" v-if="all_price - all_sum > 0">
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{ $t("Discount") }}</h3>
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all"> - {{all_price - all_sum}} {{$t('Sum')}}</h3>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all-price">{{ $t("Products sum") }}<span style="opacity: 0.5">({{selected_products.length}})</span></h3>
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{all_sum - selected_coupon.coupon_price}} {{$t('Sum')}}</h3>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{ $t("Delivery") }}</h3>
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">18 000 {{$t('Sum')}}</h3>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{ $t("Total amount") }}</h3>
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all">{{all_sum + 18000 - selected_coupon.coupon_price}} {{$t('Sum')}}</h3>
            </div>
            <div class="mt-5" v-if="getUser">
              <div class="mt-1">
                <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all"><b>{{ $t("User name") }}</b></h3>
                <div data-aos="zoom-in" data-aos-duration="444" class="d-none" v-if="!first_name_change">
                  {{first_name = getUser.first_name}}
                </div>
                <input data-aos="zoom-in" data-aos-duration="444" type="text" class="form-control" v-model="getUser.first_name" @change="getFirstName">
              </div>
             <div class="mt-1">
               <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all"><b>{{ $t("User phone") }}</b></h3>
               <div data-aos="zoom-in" data-aos-duration="444" class="d-none" v-if="!phone_number_change">
                 {{phone_number = getUser.phone_number}}
               </div>
               <input data-aos="zoom-in" data-aos-duration="444" type="text" class="form-control" v-model="getUser.phone_number" @change="getPhoneNumber">
             </div>
            </div>
            <ul class="nav nav-pills nav-justified mt-4">
              <li class="nav-item" v-if="my_addresses.length>0">
                <a class="nav-link success active" id="deliveryAddressMenu"  @click="selectAddress('deliveryAddress')">{{ $t("Delivery address")}}</a>
              </li>
              <li class="nav-item" v-if="pick_up_addresses.length>0">
                <a class="nav-link success" id="pickUpAddressMenu" @click="selectAddress('pickUpAddress')">{{ $t("Pickup address")}}</a>
              </li>
            </ul>
            <div class="mt-3" v-if="address_type == 'pick_up'">
              <h3 class="basket_total-all"><b>{{ $t("Pickup address") }}</b></h3>
              <div v-if="pick_up_addresses.length>0">
                <select v-model="address" class="form-select" @change="addressChange" @click="addressClick">
                  <option v-for="(pick_up_address, index_) in pick_up_addresses" v-bind:key="index_" :value="pick_up_address">
                    <span class="basket_total-addres">{{pick_up_address.region.name+' '+pick_up_address.city.name+' '+pick_up_address.name }}</span>
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-3" v-if="address_type == 'delivery'">
              <h3 class="basket_total-all"><b>{{ $t("Delivery address") }}</b></h3>
<!--              <p class="basket_total-addres">{{ $t("Tashkent city, district. Chilonzar, 13, apt. 12v") }}</p>-->
              <div v-if="my_addresses.length>0">
                <select v-model="address" class="form-select" @change="addressChange" @click="addressClick">
                  <option v-for="(my_address, index) in my_addresses" v-bind:key="index" :value="my_address">
                    <span class="basket_total-addres">{{my_address.region.name+' '+my_address.city.name+' '+my_address.name }}</span>
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-start">
                <router-link class="mt-2" to="/profile-address">
                  <button data-aos="zoom-in" data-aos-duration="444" class="button_location-green" style="margin-right: 0px"><img src="../assets/uploads/icons/location-add.svg" alt="Location"></button>
                </router-link>
              </div>
            </div>
            <div class="mt-3 text-start">
              <label data-aos="zoom-in" data-aos-duration="444" class="mb-2" v-if="selected_coupon.coupon.id != ''">{{$t('Enter another coupon')}}</label>
              <form class="d-flex">
                <input data-aos="zoom-in" data-aos-duration="444" class="basket_coupon" type="text" v-model="coupon" :placeholder="$t('Enter coupon')">
                <button data-aos="zoom-in" data-aos-duration="444" class="basket_coupon-btn" @click="takeCoupon">{{ $t("Apply") }}</button>
              </form>
            </div>
            <div class="mt-4">
              <h3 data-aos="zoom-in" data-aos-duration="444" class="basket_total-all"><b>{{ $t("Payment method") }}</b></h3>
              <div class="d-flex mt-3">
                <div class="d-flex justify-content-center align-items-center">
                  <input data-aos="zoom-in" data-aos-duration="444" type="radio" id="html" class="basket_pay me-2" name="fav_language" @change="selectPayment" checked value="Cash">
                  <label data-aos="zoom-in" data-aos-duration="444" for="html">{{$t("Cash")}}</label><br>
                </div>
                <div class="d-flex justify-content-center align-items-center ms-4">
                  <input data-aos="zoom-in" data-aos-duration="444" type="radio" id="css" class="basket_pay me-2" name="fav_language" @change="selectPayment" value="Online">
                  <label data-aos="zoom-in" data-aos-duration="444" for="css">{{$t("Online")}}</label><br>
                </div>
              </div>
            </div>

<!--            <div class="mt-3" v-if="payment == 'Online'">-->
<!--              <h3 class="basket_total-all"><b>{{ $t("Select card") }}</b></h3>-->
              <!--              <p class="basket_total-addres">{{ $t("Tashkent city, district. Chilonzar, 13, apt. 12v") }}</p>-->
<!--              div v-if="getCards">-->
<!--              <div class="d-none" v-if="!card_change">-->
<!--                {{card = getCards[0]}}-->
<!--              </div>-->
<!--              <select v-model="card" class="form-select" @change="cardChange" @click="cardClick" required>-->
<!--                <option v-for="(my_card, index) in getCards" v-bind:key="index" :value="my_card">-->
<!--                  <span class="basket_total-addres">{{my_card.name+' '+my_card.card_number}}</span>-->
<!--                </option>-->
<!--              </select>-->
<!--            </div><-->
<!--              <div class="d-flex justify-content-start">-->
<!--                <router-link class="mt-2" to="/profile-pay">-->
<!--                  <button class="button_location-green" style="margin-right: 0px; color: white"><i class="fa fa-plus"></i></button>-->
<!--                </router-link>-->
<!--              </div>-->

<!--            </div>-->

            <div class="mt-4 d-flex" style="justify-content: space-around;">
              <img data-aos="zoom-in" data-aos-duration="444" width="50" height="50" src="../assets/uploads/payment-image/Payme.svg" alt="Payme">
              <img data-aos="zoom-in" data-aos-duration="444" width="50" height="50" src="../assets/uploads/payment-image/Click.svg" alt="Click">
              <img data-aos="zoom-in" data-aos-duration="444" width="50" height="50" src="../assets/uploads/payment-image/Humo.svg" alt="Humo">
              <img data-aos="zoom-in" data-aos-duration="444" width="50" height="50" src="../assets/uploads/payment-image/Uzcard.svg" alt="Uzcard">
              <img data-aos="zoom-in" data-aos-duration="444" width="50" height="50" src="../assets/uploads/payment-image/Visa.svg" alt="Visa">
            </div>
            <div class="d-flex justify-content-center">
              <button data-aos="zoom-in" data-aos-duration="444" class="mt-4 basket_order-btn" @click="checkoutOpen">{{$t('Go to checkout')}}</button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container detail_container mt-5" style="height: 464px">
          <h3 data-aos="zoom-in" data-aos-duration="444" class="comment_title pt-4">{{$t('You may like')}}</h3>
          <swiper-product></swiper-product>
        </div>
      </section>
    <footer-view></footer-view>
  </div>
</template>
<script>
  import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
  import HeaderView from "@/components/HeaderView";
  import FooterView from "@/components/FooterView";
  import router from "@/router";
  import axios from "axios";
  import {defaultLocale} from "@/i18n";
  export default {
    components: {FooterView, HeaderView},
    computed:{
      ...mapState(['backgroundModal', 'catologModal', 'loginModal', 'checkoutModal', 'checkoutModal_', 'body', 'orderOrderedInfo', "basketModal"]),
      ...mapGetters(['getProducts', 'getFavourites', 'getUser', 'getCards', "getSelectedProducts"]),
    },
    data(){
      return{
        basket_page:true,
        name:'Elyor',
        selected_products:[],
        first_name:'',
        phone_number:'',
        coupon_products:[],
        selected_products_data:[],
        selected_coupon:{
          coupon:{
            id:'',
            name:''
          },
          coupon_price:0
        },
        payment:'Cash',
        my_addresses:[],
        address_type:'delivery',
        pick_up_addresses:[],
        address:{},
        order_info:{},
        // card:{},
        all_sum:0,
        all_price:0,
        coupon_price:0,
        favourite_product:{},
        favourite_products:[],
        favourite_products_data:[],
        favourite_product_element:'no element',
        is_repeated:false,
        router_id:router.currentRoute.value.params.id,
        isLoading:true,
        coupon:'',
        address_change:false,
        // card_change:false,
        first_name_change:false,
        phone_number_change:false,
        address_changed:false,
        language:localStorage.getItem('lang')||defaultLocale
        // charaterized_products:[],
      }
    },
    methods:{
      ...mapMutations(['closeModal', "setFalsecheckoutModal_", 'catologModalFunc', "setBasketLenth", 'toFavorites', 'setFavouritedProducts', "setSelectedProducts", 'loginModalFunc', 'checkoutOpen']),
      ...mapActions(['GETPRODUCTSFUNC', 'GETCARDS']),
      plusFunc(index){
        this.selected_products[index].count++
        this.all_sum = 0
        this.all_price = 0
        this.allSumFunc()
      },
      selectAddress(status){
        if(!document.getElementById(status+'Menu').classList.contains('active')){
          document.getElementById(status+'Menu').classList.add("active");
        }
        let pickUpAddressMenu = document.getElementById("pickUpAddressMenu")
        let deliveryAddressMenu = document.getElementById("deliveryAddressMenu")
        switch (status) {
          case "pickUpAddress":
            if(this.address_type != 'pick_up') {
              this.address_type = 'pick_up'
            }
            if(deliveryAddressMenu){
              if(deliveryAddressMenu.classList.contains('active')) {
                deliveryAddressMenu.classList.remove("active");
              }
            }
            if(this.my_addresses.length>0){
              this.address = this.my_addresses[0]
            }
            break;
          case "deliveryAddress":
            if(this.address_type != 'delivery') {
              this.address_type = 'delivery'
            }
            if(pickUpAddressMenu){
              if(pickUpAddressMenu.classList.contains('active')) {
                pickUpAddressMenu.classList.remove("active");
              }
            }
            if(this.pick_up_addresses.length>0){
              this.address = this.pick_up_addresses[0]
            }
            break;
        }
      },
      selectPayment(e){
        this.payment = e.target.value.trim()
        if(this.payment == 'Cash'){
          this.card = {}
        }
      },
      addressChange(){
        this.address_change = true
      },
      addressClick(){
        if(!this.address_change){
          this.address_change = true
        }
      },
      // cardChange(){
      //   this.card_change = true
      // },
      // cardClick(){
      //   if(!this.card_change){
      //     this.card_change = true
      //   }
      // },
      minusFunc(index){
        if(this.selected_products[index].count > 0){
          this.selected_products[index].count = this.selected_products[index].count - 1
        }
        this.all_sum = 0
        this.all_price = 0
        this.allSumFunc()
      },
      getAllSum(item){
        this.all_sum = this.all_sum + parseInt(item.sum)*parseInt(item.count)
        this.all_price = this.all_price + parseInt(item.price)*parseInt(item.count)
      },
      allSumFunc(){
        this.all_sum = 0
        this.all_price = 0
        this.selected_products.forEach(this.getAllSum)
      },
      getFirstName(e){
        this.first_name = e.target.value
        this.first_name_change = true
      },
      getPhoneNumber(e){
        this.phone_number = e.target.value
        this.phone_change_change = true
      },
      async confirmOrder(){
        try{
          const res = await axios.post(process.env.VUE_APP_SERVER+'/confirm-order',
              {
                'selected_products':this.selected_products,
                "coupon":this.selected_coupon.coupon.name,
                "payment":this.payment,
                // "card_id":this.card.id,
                "address_id":this.address.id,
                "first_name":this.first_name,
                "phone_number":this.phone_number
              }, {
                headers:{
                  'Content-type':'multipart/form-data',
                  'Authorization':`Bearer ${localStorage.getItem('user_token')}`
                }
              })
          if(res.data.status == true){
            this.$toast.success(this.$t('Order confirmed !'));
            if(localStorage.getItem('selected_product')){
              localStorage.removeItem('selected_product')
            }
            if(localStorage.getItem('coupon')){
              localStorage.removeItem('coupon')
            }
            this.order_info = res.data.data
            this.setFalsecheckoutModal_()
          }else{
            this.$toast.warning(this.$t('Order not confirmed !'));
          }
        }catch (e) {
          console.log(e)
        }
      },
      confirmOrderInfoFunc() {
        this.closeModal()
        location.reload()
      },
      deleteFunc(index){
        this.selected_products.splice(index, 1)
        if(localStorage.getItem('selected_product')){
          localStorage.removeItem('selected_product')
        }
        localStorage.setItem('selected_product', JSON.stringify(this.selected_products))
        this.allSumFunc()
        this.$toast.warning(this.$t('Product deleted from basket !'));
        this.setBasketLenth(this.selected_products.length)
      },
      async takeFavouriteProducts(){
        // this.all_sum = 0
        let selected_products_id_ = []
        if(localStorage.getItem('favourite_product')){
          this.favourite_products_data = JSON.parse(localStorage.getItem('favourite_product'))
        }
        if(localStorage.getItem('selected_product')){
          selected_products_id_ = JSON.parse(localStorage.getItem('selected_product')).map(item => item.id)
        }
        try {
          this.favourite_products = await this.getFavouriteProduct(selected_products_id_)
        }catch (e) {
          console.log(e)
        }
      },
      async SelectedProducts(){
        this.all_sum = 0
        this.all_price = 0
        let selected_products = []
        this.selected_products_data = []
        if(localStorage.getItem('selected_product')){
          this.selected_products_data = JSON.parse(localStorage.getItem('selected_product'))
          for(let i=0; i<this.selected_products_data.length; i++){
            selected_products.push({
              'id':this.selected_products_data[i].id,
              'count':this.selected_products_data[i].count
            })
          }
        }

        if(localStorage.getItem('coupon')){
          this.selected_coupon = JSON.parse(localStorage.getItem('coupon'))
        }
        try {
            this.selected_products = await this.getCharacterizedProduct(selected_products, this.selected_coupon.coupon.name)
        }catch (e) {
          console.log(e)
          this.selected_products = []
        }
        if(this.selected_products != undefined && this.selected_products != null) {
          for (let i = 0; i < this.selected_products.length; i++) {
            for (let j = 0; j < this.selected_products_data.length; j++) {
              if (this.selected_products_data[j].id == this.selected_products[i].id) {
                this.selected_products[i].count = this.selected_products_data[j].count;
                this.all_sum = this.all_sum + parseInt(this.selected_products[i].sum) * parseInt(this.selected_products[i].count)
                this.all_price = this.all_price + parseInt(this.selected_products[i].price) * parseInt(this.selected_products[i].count)
              }
            }
          }
        }
        this.getSelectedProduct()
      },
      async getFavouriteProduct(selected_products_id){
        try{
          const res = await axios.post(process.env.VUE_APP_SERVER+'/get-favourite-products',
              {'selected_products_id':selected_products_id})
          if(res.data.status == true){
            return res.data.data
          }else{
            return []
          }
        }catch (e) {
          console.log(e)
          return []
        }
      },
      async getCharacterizedProduct(selected_products, coupon_name){
        try{
          const res = await axios.post(process.env.VUE_APP_SERVER+'/get-characterized-product',
          {
            'selected_products':selected_products,
            "coupon":coupon_name,
          }, {
            headers:{
              'Content-type':'multipart/form-data',
              'language':this.language,
              'Authorization':`Bearer ${localStorage.getItem('user_token')}`,
            },
          })
          if(res.data.status == true){
            this.selected_coupon.coupon_price = res.data.coupon_price
            return res.data.data
          }else{
            return []
          }
        }catch (e) {
          console.log(e)
          return []
        }
      },
      getSelectedProduct(){
        this.takeFavouriteProducts()
        for(let j=0; j<this.favourite_products_data.length; j++){
          if(this.selected_products != undefined && this.selected_products != null){
            for(let i=0; i<this.selected_products.length; i++){
              if(this.selected_products[i].product_id == this.favourite_products_data[j].id){
                Object.assign(this.selected_products[i], {'is_favourite':true})
              }
            }
          }
        }
      },
      async getCoupon(products){
        try{
          const res = await axios.post(process.env.VUE_APP_SERVER+'/get-coupon',
            {
              "coupon":this.coupon,
              "products":products,
            }, {
            headers:{
              'Content-type':'multipart/form-data',
              'Authorization':`Bearer ${localStorage.getItem('user_token')}`
            }
          })
          if(res.data.status == true){
            this.coupon_price = res.data.data.coupon_price
            if(localStorage.getItem('coupon')){
              localStorage.removeItem('coupon')
            }
            if(res.data.data.coupon.id != null && res.data.data.coupon.id != undefined){
              localStorage.setItem('coupon', JSON.stringify(res.data.data))
            }
            location.reload()
            this.$toast.success(this.$t('Coupon is added!'));
          }
        } catch (e) {
          if(e.response.data.status == false){
            this.$toast.warning(this.$t("Coupon isn't found!"));
          }
          console.log(e)
        }
      },
      async takeCoupon(e){
        e.preventDefault()
        let selected_products = []
        let products = []
        if(localStorage.getItem('selected_product')){
          selected_products = JSON.parse(localStorage.getItem('selected_product'))
          for(let i=0; i<selected_products.length; i++){
            products.push({
              'id':selected_products[i].id,
              'count':selected_products[i].count
            })
          }
        }
        try {
          this.coupon_products = await this.getCoupon(products)
        }catch (e) {
          console.log(e)
        }
      },
      toFavourite(product) {
        this.takeFavouriteProducts()
        this.is_repeated = false
        this.favourite_product.id = product.product_id
        for(let i = 0; i<this.favourite_products_data.length; i++){
          if(this.favourite_products_data[i].id == product.product_id) {
            this.is_repeated = true
            this.favourite_product_element = i
            for(let j = 0; j<this.selected_products.length; j++){
              if(this.selected_products[j].product_id == product.product_id){
                if(this.selected_products[j].is_favourite != undefined && this.selected_products[j].is_favourite != null){
                  delete this.selected_products[j].is_favourite
                }
              }
            }
          }
        }
        if(this.favourite_product_element != 'no element' && this.is_repeated == true){
          this.favourite_products_data.splice(parseInt(this.favourite_product_element), 1)
        }
        this.favourite_product_element = 'no element'
        if(this.is_repeated == false){
          for(let j = 0; j<this.selected_products.length; j++){
            if(this.selected_products[j].product_id == product.product_id){
              if(this.selected_products[j].is_favourite == undefined || this.selected_products[j].is_favourite == null){
                Object.assign(this.selected_products[j], {'is_favourite':true})
              }
            }
          }
          if(this.favourite_products_data.length == 0){
            this.favourite_products_data = [{'id':this.favourite_product.id}]
          }else {
            this.favourite_products_data.push({'id':this.favourite_product.id})
          }
        }
        if(localStorage.getItem('favourite_product')){
          localStorage.removeItem('favourite_product')
        }
        localStorage.setItem('favourite_product', JSON.stringify(this.favourite_products_data))
        if(this.is_repeated == false){
          this.$toast.success(this.$t('Product added to favourites !'));
        }else{
          this.$toast.warning(this.$t('Product deleted from favourites !'));
        }
      },
      async getAddress(){
        try{
          const addresses = await axios.get(process.env.VUE_APP_SERVER+'/get-address', {
            headers:{
              'Content-type':'Application/json',
              'language':this.language,
              'Authorization':`Bearer ${localStorage.getItem('user_token')}`
            }
          })
          this.my_addresses = addresses.data.data
          this.address = this.my_addresses[0]
        }catch (e) {
          console.log(e)
        }
      },
      async getPickUpAddress(){
        try{
          const addresses = await axios.get(process.env.VUE_APP_SERVER+'/get-pick-up-address', {
            headers:{
              'Content-type':'Application/json',
              'language':this.language,
              'Authorization':`Bearer ${localStorage.getItem('user_token')}`
            }
          })
          this.pick_up_addresses = addresses.data.data
        }catch (e) {
          console.log(e)
        }
      },
      // takeSelectedProducts(){
      //   if(this.getSelectedProducts){
      //     this.selected_products = this.getSelectedProducts
      //   }
      // },
    },
    mounted() {
      this.getAddress()
      this.getPickUpAddress()
      this.GETPRODUCTSFUNC()
      this.GETCARDS()
      this.SelectedProducts()
      this.setFavouritedProducts()
      this.allSumFunc()
      setTimeout(() => {
        this.isLoading = false; // Set to false when the loading is complete
      }, 400);
    }
  }
</script>
<style scoped>

.nav-pills .nav-link.success {
  color: black !important;
}
.nav-pills .nav-link.success:hover {
  background-color: #E2F1CF !important;
  color: black !important;
}
.nav-pills .nav-link.success.active {
  background-color: #8DC73F !important;
  color: #fff !important;
}
</style>
<style>
  .basket_products>a{
    width:70%;
    height:74%;
  }
  .basket_product_size{
    color: black;
    margin-left: 24px;
  }
  @media only screen and (max-width: 400px) {
    .basket_products{
      padding: 0 8px;
    }
  }
  @media only screen and (min-width: 769px) {
    .basket_product_size{
      font-size: 12px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .basket_product_size{
      font-size: 10px !important;
    }
  }
  /*.save_a_like-image_basket{*/
  /*  margin-right: 8px;*/
  /*}*/
  .basket_trash-btn button{
    background-color: white;
    transition:0.4s;
    display: flex;
    align-items: center;
  }
  #activeDiv{
    transition: 0.4s;
  }
  .basket_trash-btn button:hover{
    transform: scale(1.1);
  }
  #activeDiv:hover{
    transform: scale(1.1);
  }
  .product_color{
    height: 24px;
    width: 24px;
    border-radius:50%;
    border:solid 1px green;
  }
  .confirm_modal{
    background-color: white;
    z-index: 6;
    border-radius: 0.5rem;
    padding: 1rem;
  }
</style>