<template>
  <div class="bank_product-card mt-4">

    <div class="mt-3 ms-4">
      <h3 class="hello_profile" data-aos="zoom-in" data-aos-duration="444">{{$t('Online payment')}}</h3>
      <p class="addres_title ms-4 mt-1 text-start" data-aos="zoom-in" data-aos-duration="444">{{$t('Your cards')}}</p>

      <div class="d_pay">
        <div class="row" v-if="getCards">
          <div class="col-xl-6 col-md-12 d-flex justify-content-center mt-3 mb-1" v-for="card in getCards" :key="card.id">
            <div class="card_image">
              <p class="bank_name" style="opacity: 0.5;" data-aos="zoom-in" data-aos-duration="444">{{card.name}}</p>

              <p class="bank_name card_bank_number" data-aos="zoom-in" data-aos-duration="444" style="margin-bottom: 1px">**** **** **** {{card.card_number.toString().slice(-4)}}</p>
              <div class="d-flex justify-content-between">
                <p class="bank_name" data-aos="zoom-in" data-aos-duration="444">{{card.user_name}}</p>
                <img width="20" height="12" src="../assets/uploads/payment-image/image6.png" alt="humo" data-aos="zoom-in" data-aos-duration="444">
              </div>
            </div>
            <div class="d-flex flex-column ms-3 mt-3">
              <button data-aos="zoom-in" data-aos-duration="444" class="button_location-green" @click="bankEditFunc(card)"><img src="../assets/uploads/icons/message-edit.svg" alt="Edit"></button>
              <button data-aos="zoom-in" data-aos-duration="444" class="button_location-red mt-3" @click="bankDeleteFunc(card)"><img src="../assets/uploads/icons/trashWhite.svg" alt="Delete"></button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-md-12 d-flex justify-content-center mt-3 mb-1">
            <div class="card_image d-flex justify-content-center align-items-center">
              <button class="bank_plus" @click="bankPlusFunc">+</button>
            </div>
            <div class="d-flex flex-column ms-3 mt-3" style="width: 65px"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations} from "vuex"

export default {
  name:'bank-product-card-view',
  props:{
    getCards:{
      type:Object
    }
  },
  data(){
    return {
      card_number:'',
    }
  },
  methods:{
    ...mapMutations(["bankPlusFunc", "bankEditFunc", "bankDeleteFunc"]),
  },
}
</script>
<style>
  .card_bank_number{
    margin-top: 60px;
  }
</style>