<template>
    <div class="background_transparent" v-if="backgroundModal" @click="closeModal"></div>
</template>
<script>
  export default {
    name:'background-transparent-view',
    props:{
      backgroundModal:{
        required: true
      },
      closeModal:{
        type:Function
      }
    },
    data(){
      return{
        name:'Elyor',
      }
    },
  }
</script>
<style>
.background_transparent{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  background-color: rgb(0, 0, 0, 0.2)
}
</style>