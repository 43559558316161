<template>
  <background-transparent-view :closeModal="closeModal" :backgroundModal="backgroundModal"></background-transparent-view>
  <div v-if="isLoading" class="d-flex align-items-center" style="height: 100%">
    <div class="loading-spinner"></div>
  </div>
  <div v-else>
    <header-view :catologModalFunc="catologModalFunc" :catologModal="catologModal" :loginModal="loginModal" :favourite_products="getFavourites.length" :loginModalFunc="loginModalFunc"></header-view>
    <basket-modal-view v-if="basketModal" :getFavourites="getFavourites" :toFavourites="toFavourites" :setFavouritedProducts="setFavouritedProducts" @click="closeModal"></basket-modal-view>
    <section class="mt-5">
      <div class="container d-flex">
        <ul class="d-flex" style="margin-left: -35px;">
          <li class="d-flex ms-1">
            <router-link data-aos="zoom-in" data-aos-duration="444" to="/" style="text-decoration: none; color: #000000;">
              {{$t('Home')}}
            </router-link>
            <div data-aos="zoom-in" data-aos-duration="444" class="ms-1">
              <img src="../assets/uploads/icons/arrow-left.svg" alt="arrow-left">
            </div>
          </li>
          <li class="d-flex ms-1">
            <router-link data-aos="zoom-in" data-aos-duration="444" to="/profile" style="text-decoration: none; color: #000000;">
              {{$t('Personal Area')}}
            </router-link>
          </li>
<!--          <li class="d-flex ms-1">-->
<!--            <button @click="check" style="text-decoration: none; color: #000000;">-->
<!--              {{$t('check')}}-->
<!--            </button>-->
<!--          </li>-->
        </ul>
      </div>
    </section>

    <section>
      <div class="container d-flex profile_data-adaptive">
        <personal-info-menus-view :current_menu="current_menu"></personal-info-menus-view>
        <div class="personal_info_content">
          <div class="d-flex flex-column">
            <personal-information-view></personal-information-view>
            <profile-information></profile-information>
            <div class="coupon_product-card mt-4">
              <div class="mt-3 ms-4">
                <h3 data-aos="zoom-in" data-aos-duration="444" class="hello_profile">{{$t('Coupons')}}</h3>
              </div>

              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
                  <div class="coupon_yellow">
                    <coupon-product-view></coupon-product-view>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
                  <div class="coupon_red">
                    <coupon-product-view></coupon-product-view>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
                  <div class="coupon_blue">
                    <coupon-product-view></coupon-product-view>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
                  <button data-aos="zoom-in" data-aos-duration="444" class="coupon_all-btn">
                    {{$t('All')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container detail_container mt-5" style="height: 464px">
        <h3 data-aos="zoom-in" data-aos-duration="444" class="comment_title pt-4">{{$t('You may like')}}</h3>
        <swiper-product></swiper-product>
      </div>
    </section>
    <footer-view></footer-view>
  </div>
</template>
<script>
import {mapState, mapMutations, mapGetters} from 'vuex'
export default {
  computed:{
    ...mapState(['backgroundModal', 'catologModal', 'loginModal', "basketModal"]),
    ...mapGetters(["getFavourites"])
  },
  data(){
    return{
      current_menu: {page:'profile'},
      isLoading:true
    }
  },
  methods:{
    ...mapMutations(['catologModalFunc', 'closeModal', "loginModalFunc", "setFavouritedProducts", "toFavourites"]),
  },
  mounted() {
    this.setFavouritedProducts()
    setTimeout(() => {
      this.isLoading = false; // Set to false when the loading is complete
    }, 400);
  }
}
</script>