<template>
    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
      <div style="text-align: left; margin-right: -34px;" class="coupon_text mt-2">
        <h3 class="first_buy" data-aos="zoom-in" data-aos-duration="444">{{$t('First purchase')}}</h3>
        <p class="first_buy" data-aos="zoom-in" data-aos-duration="444">-10%</p>
        <img style="margin-top: -20px;" src="../assets/uploads/icons/barcode1.svg" alt="barcode" data-aos="zoom-in" data-aos-duration="444">
      </div>
    </div>
</template>
<script>
export default {
  name:'coupon-product-view'
}
</script>