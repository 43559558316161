<template>
  <div class="coupon_product-card mt-4">
    <div class="mt-3 ms-4">
      <h3 data-aos="zoom-in" data-aos-duration="444" class="hello_profile">{{$t('Coupons')}}</h3>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_yellow">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_red">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_blue">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_purple">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_green">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_orange">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_pink">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_grin">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_rid">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_blue">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_yellow">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 coupon_content">
        <div class="coupon_red">
          <coupon-product-view></coupon-product-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'coupons-product-view'
}
</script>
<style>
.coupon_product-card .row div{
  margin-bottom: 14px;
}
</style>