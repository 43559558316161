<template>
  <div>
    <div class="carusel_modal">
      <div class="carusel_modal_content_basket">
        <div id="custCarousel" class="carousel slide">
          <div class="carousel-inner">
            <div class="close_basket_modal">
              <span class="fa fa-close" @click="closeModal"></span>
            </div>
            <div class="container detail_container d-flex mt-1" @click.stop>
              <div class="detail_image-slide slide_app_detail" style="padding: 20px">
                  <button style="border: none; background-color: transparent;">
                    <img class="show-detail_big_image" :src="getBasketProduct.images[selected_image_index]" alt="shoes">
                  </button>
                  <div class="mt-1 d-flex align-items-center">
                    <button class="show-detail_arrow_button" @click="getPreviousImage">
                      <img style="transform: rotate(180deg);" src="../assets/uploads/icons/arrow-circle-right.svg" alt="Left icon">
                    </button>
                    <button v-if="getBasketProduct.images[0]" class="show-detail_mini_button" @click="showCurrentImage(0)">
                      <img class="show-detail_mini_image" :src="getBasketProduct.images[0]" alt="shoes">
                    </button>
                    <button v-if="getBasketProduct.images[1]" class="show-detail_mini_button" @click="showCurrentImage(1)">
                      <img class="show-detail_mini_image" :src="getBasketProduct.images[1]" alt="shoes">
                    </button>
                    <button v-if="getBasketProduct.images[2]" class="show-detail_mini_button" @click="showCurrentImage(2)">
                      <img class="show-detail_mini_image" :src="getBasketProduct.images[2]" alt="shoes">
                    </button>
                    <button class="show-detail_arrow_button" @click="getNextImage">
                      <img src="../assets/uploads/icons/arrow-circle-right.svg" alt="Right icon">
                    </button>
                  </div>
                </div>
              <div class="show-detail_padding" style="padding: 20px">
                <h5 v-if="getBasketProduct.name" class="detail_title_modal">
                  <b v-if="getBasketProduct.name.length>20">{{getBasketProduct.name.substring(0, 20)}}...</b>
                  <b v-else>{{getBasketProduct.name}}</b>
                </h5>
                <p class="detail_title_modal" style="opacity: 0.5; font-size: 16px;">{{getBasketProduct.company}}</p>
                <div class="flex_deteil">
                  <div class="d-flex show-detail_margin_top">
                    <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
                    <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
                    <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
                    <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
                    <img class="me-1" src="../assets/uploads/icons/Star5.svg" alt="Star">
                    <p class="detail_star">{{5}} {{$t('reviews')}}</p>
                  </div>
                  <div v-if="getBasketProduct.first_color_products && getBasketProduct.is_exist_in_warehouse == true">
                    <div v-if="getBasketProduct.first_color_products.length>0" class="d-flex show-detail_margin_top">
                      <img src="../assets/uploads/icons/bag-tick.svg" alt="Bag">
                      <p class="detail_title_modal ms-2 mt-1" style="font-size: 16px;">{{$t('Are available')}}</p>
                    </div>
                    <div v-else class="d-flex show-detail_margin_top">
                      <img src="../assets/uploads/icons/bag-no-tick.svg" alt="Bag">
                      <p class="detail_title_modal ms-2 mt-1" style="font-size: 16px;">{{$t("Aren't available")}}</p>
                    </div>
                  </div>
                  <div v-else class="d-flex show-detail_margin_top">
                    <img src="../assets/uploads/icons/bag-no-tick.svg" alt="Bag">
                    <p class="detail_title_modal ms-2 mt-1" style="font-size: 16px;">{{$t("Aren't available")}}</p>
                  </div>
                  <del v-if="getBasketProduct.price > getBasketProduct.sum" class="detail_title_modal ms-2 mt-1" style="font-size: 16px; opacity: 0.4">{{getBasketProduct.price}} {{$t('Sum')}}</del>
                  <div class="d-flex show-detail_margin_top">
                    <p v-if="product_sum != 0" class="detail_title_modal ms-2 mt-2" style="font-size: 16px;">{{product_sum}} {{$t('Sum')}}</p>
                    <p v-else class="detail_title_modal ms-2 mt-2" style="font-size: 16px;">{{getBasketProduct.sum}} {{$t('Sum')}}</p>
                  </div>
                </div>
                <div v-if="getBasketProduct.first_color_products">
                  <div v-if="getBasketProduct.first_color_products.length>0">
                    <p class="detail_number">{{$t('Color')}}</p>
                    <div class="d-flex show-detail_margin_bottom mb-2">
                      <div v-if="!size_selected" class="d-flex">
                        <span v-for="(character, j) in getBasketProduct.characters" :key="j">
                            <a v-if="!Array.isArray(character.color)" :style="'background-color:'+character.color.code" @click="selectColor(j, character)" :class="'d-flex detail_color ' + color_active[j]"></a>
                            <a v-else :class="'d-flex detail_color '+ color_active[j]" @click="selectColor(j, character)">
                              <img src="../assets/images/no_icon_red.png" alt="" width="100%">
                            </a>
                        </span>
                      </div>
                      <div v-else class="d-flex">
                        <span v-for="(character, m) in getBasketProduct.characters" :key="m">
                          <a v-if="!Array.isArray(character.color)" :style="'background-color:'+character.color.code" @click="selectColor(m, character)"  :class="'d-flex detail_color ' + color_active[m]"></a>
                          <a v-else :class="'d-flex detail_color '+ color_active[m]" @click="selectColor(m, character)">
                            <img src="../assets/images/no_icon_red.png" alt="" width="100%">
                          </a>
                        </span>
                      </div>
                    </div>
                    <p class="detail_number ">{{$t('Size')}}</p>
                    <div class="row">
                      <div class="text-align-start" v-if="!color_selected">
                        <button :class="'detail_size size_show '+selected_size[k]" v-for="(product_size, k) in getBasketProduct.first_color_products" @click="selectSize(k, getBasketProduct.first_color_products.length, product_size)" :key="k">
                          <span v-if="product_size.size == ''"><img src="../assets/images/no_icon_red.png" alt="" width="14px"></span>
                          <span v-else>{{product_size.size}}</span>
                        </button>
                      </div>
                      <div class="text-align-start" v-else>
                        <button :class="'detail_size size_show ' + selected_size[l]" v-for="(product_size, l) in character.products" :key="l" @click="selectSize(l, character.products.length, product_size)">
                          <span v-if="product_size.size == ''"><img src="../assets/images/no_icon_red.png" alt="" width="14px"></span>
                          <span v-else>{{product_size.size}}</span>
                        </button>
                      </div>
                    </div>
                    <div class="d-flex mb-1">
                      <button class="detail_minus me-2" @click="minusFunc">−</button>
                      <input class="detail_number-input" type="text" :value="product_count" id="input"/>
                      <button class="detail_minus ms-2" @click="plusFunc">+</button>
                    </div>
                    <div class="d-flex mt-1">
                      <button class="detail_buy" @click="toBasket(basket_page)">{{$t('Add to cart')}}</button>
                      <div id="activeDiv">
                        <button @click="toFavourite(favourite_page)" class="ms-3">
                          <svg v-if="!getIsFavourite" style="cursor: pointer;" width="25" height="23" id="activeSvg" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="activePath" d="M17.1582 4.14159C17.8915 4.96659 18.3332 6.04992 18.3332 7.24158C18.3332 13.0749 12.9332 16.5166 10.5165 17.3499C10.2332 17.4499 9.7665 17.4499 9.48317 17.3499C7.0665 16.5166 1.6665 13.0749 1.6665 7.24158C1.6665 4.66659 3.7415 2.58325 6.29984 2.58325C7.8165 2.58325 9.15817 3.31659 9.99984 4.44992C10.8415 3.31659 12.1915 2.58325 13.6998 2.58325" stroke="#8DC73F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          <svg v-else style="cursor: pointer;" width="25" height="23" id="activeSvg" viewBox="0 0 20 20" fill="#8DC73F" xmlns="http://www.w3.org/2000/svg">
                            <path id="activePath" d="M17.1582 4.14159C17.8915 4.96659 18.3332 6.04992 18.3332 7.24158C18.3332 13.0749 12.9332 16.5166 10.5165 17.3499C10.2332 17.4499 9.7665 17.4499 9.48317 17.3499C7.0665 16.5166 1.6665 13.0749 1.6665 7.24158C1.6665 4.66659 3.7415 2.58325 6.29984 2.58325C7.8165 2.58325 9.15817 3.31659 9.99984 4.44992C10.8415 3.31659 12.1915 2.58325 13.6998 2.58325" stroke="#8DC73F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
export default {
  name:'basket-modal-view',
  props:{
    basket_page:{
      type:Boolean,
    },
    favourite_page:{
      type:Boolean,
    }
  },
  computed:{
    ...mapGetters(["getBasketProduct", "getFavourites", "getSelectedProducts", "getIsFavourite", "getIsFavouriteRemoved"])
  },
  data(){
    return{
      name:'Elyor',
      product_count:0,
      color_active:['active'],
      selected_size:['selected_size'],
      selected_color:[''],
      selected_product:{},
      selected_product_to_basket:{},
      selected_products:[],
      all_selected_products:[],
      max_product_count:10,
      character:{},
      color_selected:false,
      is_favourite:false,
      size_selected:false,
      character__:{},
      selected_product_size:null,
      selected_product_id:'',
      selected_product_color:{},
      favourites:[],
      seenProducts:[],
      product_sum:0,
      selected_image_index:0,
    }
  },
  methods:{
    ...mapMutations(["setBasketLenth", "toFavourites_", 'setFavouritedProducts', "setSelectedProducts", "closeModal"]),
    plusFunc(){
      if(this.product_count < this.max_product_count){
        this.product_count = this.product_count + 1
      }
    },
    setProductCount(){
      if(this.getBasketProduct.first_color_products.length>0){
        if(parseInt(this.getBasketProduct.first_color_products[0].count) >0){
          this.product_count = 1
        }else{
          this.product_count = 0
        }
      }else{
        this.product_count = 0
      }
    },
    getPreviousImage(){
      if(this.selected_image_index > 0){
        this.selected_image_index = this.selected_image_index - 1
      }else{
        this.selected_image_index = this.getBasketProduct.images.length-1
      }
    },
    getNextImage(){
      if(this.selected_image_index < this.getBasketProduct.images.length-1){
        this.selected_image_index = this.selected_image_index + 1
      }else{
        this.selected_image_index = 0
      }
    },
    showCurrentImage(index){
      this.selected_image_index = index
    },
    minusFunc(){
      if(this.product_count > 0){
        this.product_count = this.product_count - 1
      }
    },
    mountProduct(){
      if(this.getBasketProduct.first_color_products.length > 0){
        this.selected_product_id = this.getBasketProduct.first_color_products[0].id
        this.selected_product_size = this.getBasketProduct.first_color_products[0].size
        this.selected_product_color = this.getBasketProduct.first_color_products[0].color
      }
    },
    selectColor(j, character){
      for(let i=0; i<this.color_active.length; i++){
        if(this.color_active[i] == 'active'){
          this.color_active[i] = ''
        }
      }
      this.color_active[j] = 'active'
      this.product_sum = character.products[0].sum
      this.max_product_count = character.products[0].count
      for(let n=0; n<character.products.length; n++){
        if(this.selected_size[n] == 'selected_size'){
          this.selected_size[n] = ''
        }
      }
      this.selected_size[0] = 'selected_size'
      this.selected_product_size = character.products[0].size
      this.selected_product_id = character.products[0].id
      this.selected_product_color = character.color
      this.character = character
      if(parseInt(character.products[0].count, 10) > 0){
        this.product_count = 1
      }else{
        this.product_count = 0
      }
      if(this.color_selected == false){
        this.color_selected = true
      }
      if(this.size_selected == true){
        this.size_selected = false
      }
    },
    selectSize(k, length, product){
      for(let n=0; n<length; n++){
        if(this.selected_size[n] == 'selected_size'){
          this.selected_size[n] = ''
        }
      }
      this.selected_size[k] = 'selected_size'
      this.selected_product_size = product.size
      this.selected_product_color = product.color
      this.selected_product_id = product.id
      // this.product_sum = product.sum
      this.selected_product = product
      if(this.size_selected == false){
        this.size_selected = true
      }
      if(parseInt(product.count, 10) > 0){
        this.product_count = 1
      }else{
        this.product_count = 0
      }
      this.max_product_count = product.count
    },
    toBasket(basket_page) {
      this.takeSelectedProducts()
      let selected_product_to_basket = {}
      if(this.selected_product_id != '' && this.selected_product_size != null && this.product_count > 0){
        if(Array.isArray(this.selected_product_color) || ![undefined, null].includes(this.selected_product_color)) {
          selected_product_to_basket.id = this.selected_product_id
          selected_product_to_basket.count = this.product_count
          if (this.selected_products.length == 0) {
            this.selected_products = [selected_product_to_basket]
          } else {
            let new_selected_products = {}
            let has_this_product = false
            for (let i = 0; i < this.selected_products.length; i++) {
              if (this.selected_products[i].id == selected_product_to_basket.id) {
                this.selected_products[i].count = parseInt(this.selected_products[i].count) + parseInt(selected_product_to_basket.count)
                has_this_product = true
              } else {
                new_selected_products = selected_product_to_basket
              }
            }
            if (Object.keys(new_selected_products).length > 0 && has_this_product == false) {
              this.selected_products.push(new_selected_products)
            }
          }
          if (localStorage.getItem('selected_product')) {
            localStorage.removeItem('selected_product')
          }
          localStorage.setItem('selected_product', JSON.stringify(this.selected_products))
          this.$toast.success(this.$t('Product added to basket !'));
          this.setBasketLenth(this.selected_products.length)
          this.closeModal()
          if (basket_page && basket_page == true) {
            location.reload()
          }
        }
      }else if(this.selected_product_id == '' || this.selected_product_size == null){
        this.$toast.warning(this.$t('Select product size !'));
      }else if(this.selected_product_color.length == 0){
        this.$toast.warning(this.$t('Select product color !'));
      }else if(this.product_count == 0){
        this.$toast.warning(this.$t('Select the number of products !'));
      }
    },

    takeFavouriteProducts(){
      if(this.getFavourites){
        this.favourites = this.getFavourites
      }
    },
    takeSelectedProducts(){
      if(this.getSelectedProducts){
        this.selected_products = this.getSelectedProducts
      }
    },
    toFavourite(favourite_page) {
      this.takeFavouriteProducts()
      this.toFavourites_(this.getBasketProduct.id)
      if(!this.getIsFavouriteRemoved){
        this.$toast.success(this.$t('Product added to favourites !'));
        this.getIsFavourite = true
      }else{
        this.$toast.warning(this.$t('Product deleted from favourites !'));
        this.getIsFavourite = false
      }
      this.closeModal()
      if(favourite_page && favourite_page == true){
        location.reload()
      }
    },
  },
  mounted(){
    this.setFavouritedProducts()
    this.setSelectedProducts()
    this.mountProduct()
    this.setProductCount()

  }
}
</script>
<style scoped>
 .close_basket_modal{
   position: absolute;
   width: 87%;
   color: black;
   text-align: end;
 }
 .close_basket_modal svg{
   margin-top: 12px;
   height: 20px;
   transition: 0.2s;
 }
 .close_basket_modal svg:hover{
   transform: scale(1.14);
 }

 @media (min-width: 400px) {
   .close_basket_modal{
     display: none;
   }
 }
  .carousel-inner{
    height: 100vh;
  }
  .detail_container{
    height: fit-content;
  }
  @media (min-width: 1200px) {
    .detail_title_modal {
      width: 400px;
    }
  }
  @media (min-width: 780px) and (max-width: 1199px) {
    .detail_title_modal {
      width: 350px;
    }
  }
  /*@media (min-width: 780px) and (max-width: 881px) {*/
  /*  .detail_title_modal {*/
  /*    width: 400px;*/
  /*  }*/
  /*}*/
  @media (min-width: 780px) {
    .carousel-inner{
      height: 100vh;
      overflow: hidden;
      display: flex;
      margin-top: 7%;
    }
    .detail_container{
      margin-bottom: 7%;
    }
  }
  @media (min-width: 580px) and (max-width: 779px) {
    .detail_title_modal{
      width: 324px;
    }
  }
  @media (max-width: 579px) {
    .detail_title_modal{
      width: 264px;
    }
  }
  @media (max-width: 779px) {
    .carousel-inner{
      height: 100vh;
      overflow: auto;
      display: flex;
      margin-top: 24%;
    }
    .detail_container{
      margin-bottom: 24%;
    }
  }
  .show-detail_mini_image{
    max-width: 80px;
    height: auto;
    border-radius: 8px;
  }
  .flex_deteil{
    line-height: 18px;
  }
</style>
<style>
.carusel_modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.swiper_main_image>img{
  border-radius:40px;
  border:solid 2px green;
  height: 394px;
  width: 394px;
}
#custCarousel{
  padding: 1rem;
}
.show-detail_big_image{
  max-width: 244px;
  height: auto;
  border-radius: 14px;
  border: solid 2px green;
  object-fit: cover;
}
.show-detail_mini_button{
  max-width: 108px;
  height: auto;
  border-radius: 8px;
  border: solid 1px green;
  background-color: white;
  margin-right: 0.5rem;
}
@media screen and (max-width: 480px){
  .show-detail_mini_button, .show-detail_arrow_button{
    margin-right: 1px !important;
  }
}
.detail_color{
  border: solid 1px silver;
}
.selected_size{
  background-color: #8DC73F;
  color:white;
}
.size_show{
  margin-bottom: 14px;
}
#activeSvg{
  transition: 0.4s;
}
#activeSvg:hover {
  transform: scale(1.1);
}
#activeDiv button{
  background-color: white;
}
.text-align-start{
  text-align: start;
}

.detail_comment:hover, .comment_description:hover{
  color:black;
}
.show-detail_arrow_button{
  background-color: white;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.show-detail_arrow_button img{
  margin-left: -0.5rem;
}

@media (min-width: 400px) and (max-width: 480px) {
  .show-detail_mini_image {
    height: 65px !important;
  }
}
@media (max-width: 399px) {
  .show-detail_mini_image {
    height: 55px !important;
  }
}
.show-detail_padding>.detail_title_modal{
  line-height: normal;
}

</style>