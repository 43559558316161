<template>
  <div>
    <span>Recommendations</span>
  </div>
</template>
<script>
export default {
  data(){
    return{
      name:'Elyor'
    }
  }
}
</script>