import { createStore } from 'vuex'
import router from "../router/index";
import axios from "axios";
import {defaultLocale} from "@/i18n";

export default createStore({
  state: {
    backgroundModal: false,
    catologModal: false,
    custModal: false,
    basketModal: false,
    checkoutModal_: true,
    createAddressModal:false,
    checkoutModal:false,
    orderOrderedInfo:false,
    historyModal:false,
    createBankCard:false,
    editBankCard:false,
    photoDeleteModal:false,
    edit_card_data: {},
    editAddressModal:false,
    delete_card_data: {},
    deleteBankCard:false,
    deleteAddressModal:false,
    products: [],
    all_products: [],
    categories: {},
    product: {
      images: [''],
      sizes: [''],
      colors_id: ['']
    },
    basket_product: {
      images: [''],
      sizes: [''],
      colors_id: ['']
    },
    language:localStorage.getItem('lang')||defaultLocale,
    is_favourite:false,
    all_cards:[],
    body: document.querySelector('body'),
    bestProducts: [],
    favourite_product: {},
    favourite_products: [],
    selected_products: [],
    seen_products: [],
    get_products_by_categories: [],
    get_products_by_sub_categories: [],
    is_repeated: false,
    loginModal: false,
    logout_modal: false,
    basket_length: 0,
    user: {},
    is_auth: false,
    delete_address:{},
    edit:{
      'region':{},
      'city':{},
      'cities':{},
      'zoom':7,
      'LatLon':[41.311081, 69.240562],
      'city_id':null,
      'selected':false,
      'address':''
    }
  },
  getters: {
    isAuth: function (state) {
      return state.is_auth
    },
    basket: function (state) {
      return state.basket
    },
    getBasketLength: function (state) {
      return state.basket_length
    },
    getUser: function (state) {
      return state.user
    },
    getCards: function (state) {
      return state.all_cards
    },
    getEditCard: function (state) {
      return state.edit_card_data
    },
    getDeleteCard: function (state) {
      return state.delete_card_data
    },
    getOneProduct: function (state) {
      return state.product
    },
    getBasketProduct: function (state) {
      return state.basket_product
    },
    getProducts: function (state) {
      return state.products
    },
    getAllProducts: function (state) {
      return state.all_products
    },
    getbestProducts: function (state) {
      return state.bestProducts
    },
    getFavourites: function (state) {
      return state.favourite_products
    },
    getSelectedProducts: function (state) {
      return state.selected_products
    },
    getProductsByCategories: function (state) {
      return state.get_products_by_categories
    },
    getProductsBySubCategories: function (state) {
      return state.get_products_by_sub_categories
    },
    getAddressEdit: function (state){
      return state.edit
    },
    getAddressDelete: function (state){
      return state.delete_address
    },
    getSeenProducts: function (state){
      return state.seen_products
    },
    getIsFavourite: function (state){
      return state.is_favourite
    },
    getIsFavouriteRemoved: function (state){
      return state.is_repeated
    }
  },
  mutations: {
    closeModal: function (state) {
      if (state.backgroundModal == true) {
        state.body.style.overflow = "visible"
        state.backgroundModal = false
      }
      if (state.catologModal == true) {
        state.catologModal = false
      }
      if (state.custModal == true) {
        state.custModal = false
      }
      if (state.loginModal == true) {
        state.loginModal = false
      }
      if (state.createAddressModal == true) {
        state.createAddressModal = false
      }
      if (state.editAddressModal == true) {
        state.editAddressModal = false
      }
      if (state.createBankCard == true) {
        state.createBankCard = false
      }
      if (state.editBankCard == true) {
        state.editBankCard = false
      }
      if (state.deleteAddressModal == true) {
        state.deleteAddressModal = false
      }
      if (state.deleteBankCard == true) {
        state.deleteBankCard = false
      }
      if (state.checkoutModal == true) {
        state.checkoutModal = false
        if(state.checkoutModal_ == false){
          location.reload()
        }
      }
      if (state.orderOrderedInfo == true) {
        state.orderOrderedInfo = false
      }
      if (state.historyModal == true) {
        state.historyModal = false
      }
      if (state.logout_modal == true) {
        state.logout_modal = false
      }
      if (state.photoDeleteModal == true) {
        state.photoDeleteModal = false
      }
      if (state.basketModal == true) {
        state.basketModal = false
      }
    },
    setFalsecheckoutModal_(state){
      state.checkoutModal_ = false
    },
    setBasketLenth: function (state, l) {
      state.basket_length = l
    },
    mountedBasketLenth: function (state) {
      let basket_length_ = []
      if(localStorage.getItem('selected_product')){
        basket_length_ = JSON.parse(localStorage.getItem('selected_product'))
      }
      state.basket_length = basket_length_.length
    },

    catologModalFunc: function (state) {
      if (state.catologModal == false) {
        state.catologModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    loginModalFunc: function (state) {
      if (state.loginModal == false) {
        state.loginModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    openCustModal: function (state) {
      if (state.custModal == false) {
        state.custModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    openBasketModal: function (state, basket) {
      state.is_favourite = false
      if (state.basketModal == false) {
        state.basketModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
      state.basket_product = basket
      for(let j=0; j<state.favourite_products.length; j++){
        if(state.basket_product.id == state.favourite_products[j].id){
          state.is_favourite = true
        }
      }
    },
    bankPlusFunc: function (state){
      if (state.createBankCard == false) {
        state.createBankCard = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    bankEditFunc: function (state, card){
      if (state.editBankCard == false) {
        state.editBankCard = true
      }
      state.edit_card_data = card
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    bankDeleteFunc: function (state, card){
      if (state.deleteBankCard == false) {
        state.deleteBankCard = true
      }
      state.delete_card_data = card
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    photoDeleteFunc: function (state){
      if (state.photoDeleteModal == false) {
        state.photoDeleteModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    checkoutOpen: function (state){
      if (state.checkoutModal == false) {
        state.checkoutModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    historyDelete: function (state){
      if (state.historyModal == false) {
        state.historyModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    logoutModal: function (state){
      if (state.logout_modal == false) {
        state.logout_modal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    addLocation: function (state){
      if (state.createAddressModal == false) {
        state.createAddressModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
    },
    editLocation: function (state, address){
      if (state.editAddressModal == false) {
        state.editAddressModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
      state.my_edit_address = address
      state.edit.id = address.id
      state.edit.region = address.region
      if(address.name){
        state.edit.name = address.name
      }else{
        state.edit.name = ''
      }
      state.edit.city = address.city
      state.edit.cities = address.region_cities
      state.edit.zoom = 15
      state.edit.LatLon =  [address.latitude, address.longitude]
      state.edit.city_id = null
      state.edit.selected = false
      state.edit.address =''
    },
    destroyLocation: function (state, address){
      if (state.deleteAddressModal == false) {
        state.deleteAddressModal = true
      }
      if (state.backgroundModal == false) {
        state.body.style.overflow = "hidden"
        state.backgroundModal = true
      }
      state.delete_address.id = address.id,
      state.delete_address.region = address.region,
      state.delete_address.name = address.name,
      state.delete_address.city = address.city
    },
    toFavourites(state) {
      state.is_repeated = false
      state.favourite_product.id = state.product.id
      for (let i = 0; i < state.favourite_products.length; i++) {
        if (state.favourite_products[i].id == state.product.id) {
          state.is_repeated = true
          state.favourite_products.splice(i, 1)
        }
      }
      if (state.is_repeated == false) {
        if (state.favourite_products.length == 0) {
          state.favourite_products = [state.favourite_product]
        } else {
          state.favourite_products.push(state.favourite_product)
        }
      }
      if (localStorage.getItem('favourite_product')) {
        localStorage.removeItem('favourite_product')
      }
      localStorage.setItem('favourite_product', JSON.stringify(state.favourite_products))
    },
    toFavourites_(state, product_id) {
      state.is_repeated = false
      state.favourite_product.id = product_id
      for (let i = 0; i < state.favourite_products.length; i++) {
        if (state.favourite_products[i].id == product_id) {
          state.is_repeated = true
          state.is_favourite = false
          state.favourite_products.splice(i, 1)
        }
      }
      if (state.is_repeated == false) {
        state.is_favourite = true
        if (state.favourite_products.length == 0) {
          state.favourite_products = [state.favourite_product]
        } else {
          state.favourite_products.push(state.favourite_product)
        }
      }
      if (localStorage.getItem('favourite_product')) {
        localStorage.removeItem('favourite_product')
      }
      localStorage.setItem('favourite_product', JSON.stringify(state.favourite_products))
    },
    setFavouritedProducts(state) {
      if (localStorage.getItem('favourite_product')) {
        state.favourite_products = JSON.parse(localStorage.getItem('favourite_product'))
      }
    },
    setSelectedProducts(state) {
      if (localStorage.getItem('selected_product')) {
        state.selected_products = JSON.parse(localStorage.getItem('selected_product'))
      }
    },
    SET_USER: (state, user) => {
      state.user = user
      state.is_auth = true
      localStorage.setItem('user_token', user.token)
      localStorage.setItem('is_auth', true)
    },
    SET_CARDS: (state, all_cards) => {
      state.all_cards = all_cards
    },
    SET_PRODUCTS_TO_STATE: (state, products) => {
      state.products = products.data
    },
    SET_ALL_PRODUCTS_TO_STATE: (state, products) => {
      state.all_products = products.data
    },
    SET_PRODUCT_TO_STATE: (state, product) => {
      state.product = product.data
    },
    SET_BASKET_PRODUCT_TO_STATE: (state, product) => {
      state.all_basket_product = product.data
    },
    SET_BEST_SELLER_TO_STATE: (state, best_product) => {
      state.bestProducts = best_product.data
    },
    SET_PRODUCTS_BY_CATEGORIES_TO_STATE: (state, product) => {
      state.get_products_by_categories = product.data
    },
    SET_PRODUCTS_BY_SUB_CATEGORIES_TO_STATE: (state, sub_product) => {
      state.get_products_by_sub_categories = sub_product.data
    },
    SET_SEEN_PRODUCTS: (state, seenProducts) => {
      state.seen_products = seenProducts
    },
  },
  actions: {
    async GETUSER({commit}) {
      try {
        const response = await axios.get(process.env.VUE_APP_SERVER+'/get-user', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
            'Accept': 'application/json'
          }
        })
        commit('SET_USER', response.data.data)
      } catch (e) {
        if (localStorage.getItem('is_auth') != undefined || localStorage.getItem('is_auth') != null) {
          localStorage.removeItem('is_auth')
        }
        console.log(e)
        return e
      }
    },
    async GETCARDS({commit}){
      try{
        const response = await axios.get(process.env.VUE_APP_SERVER+'/get-cards',
            {
              headers:{
                'Access-Control-Allow-Origin': '*',
                'Content-Type':'application/x-www-form-urlencoded',
                "Authorization":`Bearer ${localStorage.getItem('user_token')}`
              }
            })
        commit('SET_CARDS', response.data.data)
      }catch (e) {
        console.log(e)
      }
    },
    async GETPRODUCTSFUNC({commit, state}) {
      return await axios(process.env.VUE_APP_SERVER+"/products", {
        method: "GET",
        accepted: "Application/json",
        headers:{
          language:state.language
        }
      })
          .then((products) => {
            commit('SET_PRODUCTS_TO_STATE', products.data)
            return products.data
          })
          .catch((e) => {
            console.log(e)
            return e
          })
    },
    async GETALLPRODUCTSFUNC({commit}) {
      return await axios(process.env.VUE_APP_SERVER+"/all-products", {
        method: "GET",
        accepted: "Application/json"
      })
          .then((products) => {
            commit('SET_ALL_PRODUCTS_TO_STATE', products.data)
            return products.data
          })
          .catch((e) => {
            console.log(e)
            return e
          })
    },
    async GETPRODUCTFUNC({commit, state}) {
      return await axios(process.env.VUE_APP_SERVER+"/product", {
        method: "GET",
        accepted: "Application/json",
        headers:{
          language:state.language,
          id:router.currentRoute.value.params.id
        },
      })
          .then((product) => {
            commit('SET_PRODUCT_TO_STATE', product.data)
            return product.data
          })
          .catch((e) => {
            console.log(e)
            return e
          })
    },
    async GETBESTSELLER({commit}) {
      return await axios(process.env.VUE_APP_SERVER+"/best-seller", {
        method: "GET",
        accepted: "Application/json"
      })
          .then((best_products) => {
            commit('SET_BEST_SELLER_TO_STATE', best_products.data)
            return best_products.data
          })
          .catch((e) => {
            console.log(e)
            return e
          })
    },
    async GETPRODUCTSBYCATEGORIES({commit, state}) {
      return await axios(process.env.VUE_APP_SERVER+"/get-products-by-categories", {
        method: "GET",
        accepted: "Application/json",
        headers:{
          language:state.language,
        },
      })
          .then((products_by_category) => {
            commit('SET_PRODUCTS_BY_CATEGORIES_TO_STATE', products_by_category.data)
            return products_by_category.data
          })
          .catch((e) => {
            console.log(e)
            return e
          })
    },
    async GETPRODUCTSBYSUBCATEGORIES({commit}) {
      return await axios(process.env.VUE_APP_SERVER+"/get-products-by-sub-categories/" + router.currentRoute.value.params.id, {
        method: "GET",
        accepted: "Application/json"
      })
          .then((sub_products) => {
            commit('SET_PRODUCTS_BY_SUB_CATEGORIES_TO_STATE', sub_products.data)
            return sub_products.data
          })
          .catch((e) => {
            console.log(e)
            return e
          })
    },

    async GETSEENPRODUCTS(commit){
      let selected_products_id_ = []
      if(localStorage.getItem('seen_products')){
        selected_products_id_ = JSON.parse(localStorage.getItem('seen_products'))
      }
      try {
        const res = await this.getSeenProducts_(selected_products_id_)
        commit('SET_SEEN_PRODUCTS', res)
        return res
      }catch (e) {
        console.log(e)
      }
    },
    async getSeenProducts_(selected_products_id){
      try{
        const res = await axios.post(process.env.VUE_APP_SERVER+'/get-favourite-products',
            {'selected_products_id':selected_products_id})
        if(res.data.status == true){
          return res.data.data
        }else{
          return []
        }
      }catch (e) {
        console.log(e)
        return []
      }
    },
  },
  modules: {}
})
