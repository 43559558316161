<template>
  <div class="container catogiro_desktop">
    <div class="categories__top d-flex">
      <h2 class="categories__title">{{$t('Popular categories')}}</h2>
      <!--          <router-link to="/show-detail" id="cttg_btn" class="categories__btn d-flex" style="text-decoration: none;">{{$t('View all')}}<span class="categories__span">></span></router-link>-->
    </div>
    <div class="categories__cards">
      <router-link to='/products/2' class="categories__card categories__card_health d-flex">
        <img src='../assets/uploads/home-image/no-name-11.svg' alt="shampoo" class='categories__img' data-aos="zoom-in" data-aos-duration="444"/>
        <p class="categories__txt" data-aos="zoom-in" data-aos-duration="444">{{$t('Beauty and health')}}</p>
      </router-link>
      <router-link to='/products/0' class="categories__card categories__card_shoes">
        <p class="categories__txt" data-aos="zoom-in" data-aos-duration="444">{{$t('Shoes')}}</p>
        <img src='../assets/uploads/home-image/Rectangle21.svg' alt="shoe" class='categories__img' data-aos="zoom-in" data-aos-duration="444"/>
      </router-link>
      <router-link to='/products/3' class="categories__card categories__card_pill">
        <p class="categories__txt" data-aos="zoom-in" data-aos-duration="444">{{$t('Medicines and vitamins')}}</p>
        <img src='../assets/uploads/home-image/Rectangle21(1).svg' alt="pill" class='categories__img' data-aos="zoom-in" data-aos-duration="444"/>
      </router-link>
      <router-link to='/products/4' class="categories__card categories__card_accessory">
        <img src='../assets/uploads/home-image/Rectangle21(3).svg' alt="accsessory" class='categories__img' data-aos="zoom-in" data-aos-duration="444"/>
        <p class="categories__txt" data-aos="zoom-in" data-aos-duration="444">{{$t('Accessories')}}</p>
      </router-link>
      <router-link to='/products/5' class="categories__card categories__card_toys">
        <p class="categories__txt" data-aos="zoom-in" data-aos-duration="444">{{$t('Toys')}}</p>
        <img src='../assets/uploads/home-image/Rectangle19.svg' alt="toy" class='categories__img' data-aos="zoom-in" data-aos-duration="444"/>
      </router-link>
      <router-link to='/products/1' class="categories__card categories__card_clothes">
        <img src='../assets/uploads/home-image/Rectangle21(2).svg' alt="clothes" class='categories__img' data-aos="zoom-in" data-aos-duration="444"/>
        <p class="categories__txt" data-aos="zoom-in" data-aos-duration="444">{{$t('Cloth')}}</p>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name:'category-desktop',
}
</script>