<template>
  <div class="seen_product-card mt-4">
    <div class="mt-3 margin-profile d-flex justify-content-between">
      <h3 data-aos="zoom-in" data-aos-duration="444" class="hello_profile">{{$t('Last views')}}</h3>
      <button data-aos="zoom-in" data-aos-duration="444" class="delete_history" @click="historyDelete">{{$t('Clear the history')}} <img style="margin-top: -5px;" src="../assets/uploads/icons/trashWhite.svg" alt=""></button>
    </div>
    <div class="seen_product_modal row">
      <div class="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center" v-for="(product, index) in seenProducts" :key="index"
      style="height: 394px">
        <a @click="$router.push('/show-detail/'+product.id)" id="card_like" style="width: 100%;">
          <product-seen :basket="product" @mouseenter="showBasket(index)" @mouseleave="fromBasket(index)"></product-seen>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import axios from "axios";

export default {
  name:'last-seen-view',
  computed:{
    ...mapGetters(["basket", 'getProducts']),
  },
  props:{
    current_menu:{
      type:Object,
      required:true
    },
    historyDelete:{
      type:Function,
    }
  },
  data(){
    return{
       name:"Elyor",
       seenProducts:[],
    }
  },
  methods:{
    // ...mapMutations(["historyDelete"]),
    ...mapActions(['GETPRODUCTSFUNC']),
    showBasket(i){
      console.log(this.seenProducts[i])
      if(this.seenProducts[i].basket_button == false){
        this.seenProducts[i].basket_button = true;
      }
    },
    fromBasket(i){
      if(this.seenProducts[i].basket_button == true){
        this.seenProducts[i].basket_button = false;
      }
    },
    async takeSeenProducts(){
      let seen_products_id_ = []
      if(localStorage.getItem('seen_products')){
        seen_products_id_ = JSON.parse(localStorage.getItem('seen_products'))
      }
      try {
        this.seenProducts = await this.getSeenProduct(seen_products_id_)
      }catch (e) {
        console.log(e)
      }
    },
    async getSeenProduct(selected_products_id){
      try{
        const res = await axios.post(process.env.VUE_APP_SERVER+'/get-favourite-products',
            {'selected_products_id':selected_products_id})
        if(res.data.status == true){
          return res.data.data
        }else{
          return []
        }
      }catch (e) {
        console.log(e)
        return []
      }
    }
  },
  mounted() {
    this.takeSeenProducts()
    this.GETPRODUCTSFUNC()
  }
}
</script>
<style>
.seen_product_modal{
  flex-wrap: wrap;
  height: auto;
  overflow-y: scroll;
}
</style>